import { GET_SETTING, GET_SETTING_FAIL, GET_SETTING_SUCCESS, SET_TYPE_REG, SET_TYPE_REG_SUCCESS } from "./actionTypes"

export const getSetting = (params) => ({
    type: GET_SETTING,
    params
})

export const getSettingSuccess = setting => ({
    type: GET_SETTING_SUCCESS,
    payload: setting,
})

export const getSettingFail = error => ({
    type: GET_SETTING_FAIL,
    payload: error,
})

export const setTypeReg = type => ({
    type: SET_TYPE_REG,
    payload: type,
})

export const setTypeRegSuccess = type => ({
    type: SET_TYPE_REG_SUCCESS,
    payload: type,
})
