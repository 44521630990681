import React, { Component, useEffect, useState } from 'react'
import Webcam from "react-webcam";
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { setThing } from '../../../store/actions';
import CheckReload from '../../RouterURL/CheckReload';

const ThingCarrying = (props) => {
    const params = useParams();
    const [errors, setErrors] = useState({});

    const { onSetThing, meetingType } = props;
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState('');
    const [isInit, setIsInit] = useState(false);
    const [isCapture, setIsCapture] = useState(false);
    const webcamRef = React.useRef(null);
    const [thingCarrying, setThingCarrying] = useState({ image: null, description: '' });

    const handelValidate = () => {
        let errors = {};
        let isNext = true;

        if (!thingCarrying.description) {
            errors.name = {
                type: "required",
                message: "Vui lòng mô tả vật mang theo"
            };
            isNext = false;
        }

        if (!thingCarrying.image) {
            errors.image = {
                type: "required",
                message: "Vui lòng chụp ảnh vật mang theo"
            };
            isNext = false;
        }

        setErrors(errors);
        return isNext;
    }

    useEffect(() => {
        if (meetingType.isActive_things_carry === 0 && meetingType.isActive_photo_carry === 0) {
            return navigate("/nda/" + params.customer_id)
        }

    }, [meetingType])

    const handleNext = () => {
        if (handelValidate()) {
            onSetThing(thingCarrying);
            return navigate("/nda/" + params.customer_id)
        }
    }

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
        thingCarrying.image = imageSrc;
        setThingCarrying(thingCarrying);
        setIsCapture(true);
    }

    const retake = () => {
        setImageSrc("");
        setIsCapture(false);
        thingCarrying.image = null;
        setThingCarrying(thingCarrying);
    };

    useEffect(() => {
        if (webcamRef.current) {
            setIsInit(true);
        }

    }, [setIsInit])

    const onChangeInput = (e) => {
        thingCarrying.description = e.target.value;
        setThingCarrying(thingCarrying);
    }

    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/meeting-types/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>

                        </div>
                    </div>
                    <div className='text-center'>
                        <h5 className='mt-4'>Vật mang theo</h5>

                        <img width="200px" className='mb-3' alt='' src='/images/thing_carrying.jpg' />
                    </div>

                    <div className="row align-items-center">
                        <div className="col-12">
                            <form>


                                <div className="form-group">
                                    <label className='text-dark'>Mô tả vật mang theo</label>
                                    <input
                                        className='form-control'
                                        placeholder={"Vui lòng nhập mô tả vật mang theo..."}
                                        defaultValue={thingCarrying.description}
                                        onChange={onChangeInput}
                                    />
                                    {errors.name && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.name.message}</span>
                                    )}
                                </div>
                                {isCapture && (
                                    <div className="row justify-content-center">
                                        <div className="form-group">
                                            <TakePhoto imgsrc={imageSrc} />
                                        </div>
                                        <div className="form-group">
                                            <button type="button" className="btn bg-gradient-info form-control" onClick={retake}><i className="fa-solid fa-clock-rotate-left"></i> Chụp lại</button>
                                        </div>

                                    </div>
                                )}
                                {!isCapture && (
                                    <div className="row justify-content-center">
                                        <div className="col-xl-6">
                                            <label className='text-dark'>Chụp ảnh vật mang theo</label>
                                            <Webcam className="border border-secondary rounded" width="100%" audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                                            {errors.image && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.image.message}</span>
                                            )}
                                            {isInit && (
                                                <>
                                                    <br />
                                                    <div className="form-group">
                                                        <button type="button" className="btn bg-gradient-primary form-control" onClick={capture}><i className='fa-solid fa-camera'></i> Chụp ảnh</button>
                                                    </div>
                                                </>
                                            )}


                                        </div>
                                    </div>

                                )}
                                <div className='row mt-3'>
                                    <div className='col-12 text-center'>
                                        <a onClick={handleNext} style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>Tiếp tục <i className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <CheckReload></CheckReload>
        </>
    );
}

ThingCarrying.propTypes = {
    onSetThing: PropTypes.func,
    meetingType: PropTypes.object
}

const mapStateToProps = ({ visitor }) => ({
    meetingType: visitor.meetingType,
})

const mapDispatchToProps = dispatch => ({
    onSetThing: (thing) => dispatch(setThing(thing)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThingCarrying);

class TakePhoto extends Component {

    render() {

        const imgsrc = this.props.imgsrc;
        return (
            <div>
                <img className='mt-3 rounded' width="100%" src={imgsrc} alt="" />
            </div>
        );
    }
}