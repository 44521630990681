import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { resetState } from '../../../store/actions';
import CheckReload from '../../RouterURL/CheckReload';

const CheckoutModule = (props) => {
    const { setting, onSetVisitorToDefault } = props;

    useEffect(() => {
        onSetVisitorToDefault();
    }, [onSetVisitorToDefault])

    const params = useParams();
    const device_code = localStorage.getItem('secret_code');

    return (

        <div className="card">
            <div className="card-body text-lg-start text-center pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <a href={'/' + params.customer_id + "/" + device_code} style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</a>
                    </div>
                </div>
                <div className='text-center'>
                    <h5 className='mt-4'>PHƯƠNG THỨC CHECKOUT</h5>

                    <img width="200px" className='mb-3' alt='' src='/images/Qr-scan.jpg' />
                </div>
                <Link to={"/qr-checkout/" + params.customer_id}>
                    <button className="btn btn-icon form-control bg-gradient-danger d-lg-block mt-3 mb-0">
                        <i className="fs-5 fa-solid fa-qrcode ms-1 m-2"></i>
                        Quét mã QR
                    </button>
                </Link>

                <Link to={"/checkout/face-streaming/" + params.customer_id}>
                    <button className="btn btn-icon form-control bg-gradient-success d-lg-block mt-3 mb-0">
                        <div className='d-flex align-items-center justify-content-center'>
                            <i className="fs-4 fa-regular fa-id-badge"></i>
                            <p className='m-0 ms-2 font-weight-bold'>
                                Nhận diện khuôn mặt
                                <br />
                                <span className='fw-light text-start m-0'>(Vui lòng <span className='font-weight-bold'>không</span> đeo khẩu trang)</span>
                            </p>
                        </div>
                    </button>
                </Link>

                <Link to={"/checkout/face-capture/" + params.customer_id}>
                    <button className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0">

                        <div className='d-flex align-items-center justify-content-center'>
                            <i className="fs-5 fa-solid fa-camera"></i>
                            <p className='m-0 ms-2 font-weight-bold'>
                                Chụp ảnh khuôn mặt
                                <br />
                                <span className='fw-light text-start m-0'>(Vui lòng <span className='font-weight-bold'>không</span> đeo khẩu trang)</span>
                            </p>
                        </div>
                    </button>
                </Link>
            </div>
            <CheckReload></CheckReload>
        </div>
    );
}

CheckoutModule.propTypes = {
    setting: PropTypes.object
}

const mapStateToProps = ({ setting }) => ({
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onSetVisitorToDefault: () => dispatch(resetState())
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModule);