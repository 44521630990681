import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import RouterURL from '../RouterURL/RouterURL';

function App() {
  return (
    <Router>
      <Header />
      <div className="mt-n8">
        <div className="row m-1">
          <div className="col-lg-6 offset-lg-3 mb-lg-0 mb-4">
            <RouterURL></RouterURL>
          </div>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
