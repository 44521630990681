import React, { useEffect, useState, useRef } from 'react'
import QrReader from 'react-web-qr-reader'
import { useNavigate, useParams, Link } from 'react-router-dom';
import swal from 'sweetalert';
import "./index.css";

import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { getVisitorByQR, checkout } from '../../../../store/actions';
import _ from "lodash"
import CheckReload from '../../../RouterURL/CheckReload';

const QRCheckout = (props) => {
    const { onCheckout, checkoutMessage } = props;
    const qrRef = useRef(null);
    const [facingMode, setFacingMode] = useState("environment");
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (checkoutMessage?.status_code === 200) {
            swal({
                icon: "success",
                title: "Success!",
                text: "Checkout thành công. Vui lòng nhận lại giấy tờ!",
                type: "success",
                timer: 2000,
                showConfirmButton: false
            }).then(function () {
                let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
                localStorage.clear();
                window.location.href = url_home;
            });
        } else if (_.isEmpty(this.props.checkoutMessage)) {
            swal({
                icon: "error",
                title: "Lỗi 205!",
                text: 'Không tìm thấy dữ liệu. Bạn chưa đăng ký gặp!!!',
                type: "error",
                timer: 5000,
                showConfirmButton: false
            }).then(function () {
                let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
                localStorage.clear();
                window.location.href = url_home;
            });
        }
    }, [checkoutMessage])

    const handleScan = result => {

        if (result) {
            let data = {
                customer_id: params.customer_id,
                code: result.data,
                module: 'qr'
            }
            onCheckout(data);
            // Gửi data để checkout
        }
    }
    const handleError = err => {
        console.error(err)
    }

    const handelFacingMode = () => {
        if (facingMode === 'environment') {
            setFacingMode('user');
        } else if (facingMode === 'user') {
            setFacingMode('environment');
        }
    }

    return (
        <div className="card">
            <div className="card-body text-lg-start text-center pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <Link to={'/checkout-module/' + params.customer_id}>
                            <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                        </Link>
                    </div>
                </div>
                <div className='text-center'>
                    <h5 className='mt-4'>Quét QR checkout</h5>

                    <img width="200px" alt='' src='/images/scan-qr-icon.png' />
                    <QrReader
                        ref={qrRef}
                        className="qr-image-wrapper"
                        facingMode={facingMode}
                        // // delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />



                </div>
                <div className='row'>
                    <div className='col-12'>
                        <a onClick={handelFacingMode} className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0">
                            <i className="fa-solid fa-camera-rotate me-1"></i>
                            <span>Xoay</span>
                        </a>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </div>
    )
}

QRCheckout.propTypes = {
    visitorStatusCode: PropTypes.number,
    visitor: PropTypes.object,
    setting: PropTypes.object,
    onGetVisitor: PropTypes.func,
    onCheckout: PropTypes.func,
    checkoutMessage: PropTypes.any
}

const mapStateToProps = ({ visitor, setting }) => ({
    visitor: visitor.data,
    visitorStatusCode: visitor.status,
    setting: setting.setting,
    checkoutMessage: visitor.checkout_message
})

const mapDispatchToProps = dispatch => ({
    onGetVisitor: (data) => dispatch(getVisitorByQR(data)),
    onCheckout: (data) => dispatch(checkout(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QRCheckout)
