const visitor_group = '/v2/qiosk';
export const GET_SETTING = visitor_group + "/setting/get"

export const GET_VISITOR = visitor_group + "/visitor/get-info-by-qr"
export const GET_VISITOR_BY_FACE = visitor_group + "/visitor/get-info"

export const GET_COUNTRY = "/nation"

export const GET_MEETING_TYPE = visitor_group + "/visitor-type/get"

export const GET_HOST = visitor_group + "/employee/get"

export const GET_EMPLOYEE = visitor_group + "/employee/get/host"

export const STORE_MEETING = visitor_group + "/visitor/store"

export const CHECKOUT = visitor_group + "/visitor/checkout"