import { call, delay, put, takeEvery, takeLatest, take } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_VISITOR_BY_FACE,
    GET_VISITOR_BY_QR,
    STORE_VISITOR,
    UPDATE_VISITOR,

    SET_HOST,
    SET_IMAGE,
    SET_MEETINGTYPE,
    SET_NDA,
    SET_THING,
    SET_TIME,

    SAVE_MEETING,

    RESET_STATE,
    CHECKOUT
} from "./actionTypes"
import {
    getVisitorByFaceSuccess,
    getVisitorByFaceFail,

    getVisitorByQRSuccess,
    getVisitorByQRFail,

    storeVisitorSuccess,
    updateVisitorSuccess,

    setHostSuccess,
    setImageSuccess,
    setMeetingSuccess,
    setNDASuccess,
    setThingSuccess,
    setTimeSuccess,
    setModuleSuccess,

    saveMeetingSuccess,
    saveMeetingFail,

    resetState,
    checkoutSuccess,
    checkoutFail

} from "./actions"

import { setDefaultMessage } from "../websocket/actions"


//Include Both Helper File with needed methods
import { getVisitorByFR, getVisitorByQR, storeVisitor, checkoutByFace, checkoutByQR } from "../../api/visitor"


function* fetchVisitorByFace({ payload: params }) {
    try {
        const response = yield call(getVisitorByFR, params)
        if (response.status === 200) {
            yield put(getVisitorByFaceSuccess(response))
            yield put(setModuleSuccess(params.module))
        } else {
            yield put({ type: 'SET_STATUS', payload: response.status })
        }
    } catch (error) {
        yield put(getVisitorByFaceFail(error))
    }
}

function* fetchVisitorByQR({ payload: params }) {
    try {
        const response = yield call(getVisitorByQR, params)
        if (response.status === 200) {
            yield put(getVisitorByQRSuccess(response))
            yield put(setModuleSuccess(params.module))
        } else {
            yield put({ type: 'SET_STATUS', payload: response.status })
        }
    } catch (error) {
        yield put(getVisitorByQRFail(error))
    }
}

function* watchStoreVisitor({ payload: visitor }) {
    yield put(storeVisitorSuccess(visitor))
}

function* watchUpdateVisitor({ payload: visitor }) {
    yield put(updateVisitorSuccess(visitor))
}
// 
function* watchSetHost({ payload: data }) {
    yield put(setHostSuccess(data))
}

function* watchSetImage({ payload: data }) {
    yield put(setImageSuccess(data))
}

function* watchSetMeetingType({ payload: data }) {
    yield put(setMeetingSuccess(data))
}

function* watchSetNDA({ payload: data }) {
    yield put(setNDASuccess(data))
}

function* watchSetThing({ payload: data }) {
    yield put(setThingSuccess(data))
}

function* watchSetTime({ payload: data }) {
    yield put(setTimeSuccess(data))
}


function* watchSaveMeeting({ payload: data }) {
    try {
        const response = yield call(storeVisitor, data)
        if (response.status_code === 200) {
            yield put(saveMeetingSuccess(response))
        }
    } catch (error) {
        yield put(saveMeetingFail(error))
    }
}

function* resetStateSaga() {
    // Trigger the reset action in the reducer
    yield put({ type: "RESET_DEFAULT" });
    yield put(setDefaultMessage());
}

function* watchCheckout({ payload: data }) {
    try {
        if (data.module === 'qr') {
            const response = yield call(checkoutByQR, data)
            yield put(checkoutSuccess(response))
        } else if (data.module === 'fr') {
            const response = yield call(checkoutByFace, data)
            yield put(checkoutSuccess(response))
        }
    } catch (error) {
        yield put(checkoutFail(error))
    }

}

function* visitorSaga() {
    yield takeEvery(GET_VISITOR_BY_FACE, fetchVisitorByFace)
    yield takeEvery(GET_VISITOR_BY_QR, fetchVisitorByQR)

    yield takeLatest(STORE_VISITOR, watchStoreVisitor)
    yield takeLatest(UPDATE_VISITOR, watchUpdateVisitor)

    yield takeLatest(SET_HOST, watchSetHost)
    yield takeLatest(SET_IMAGE, watchSetImage)
    yield takeLatest(SET_MEETINGTYPE, watchSetMeetingType)
    yield takeLatest(SET_NDA, watchSetNDA)
    yield takeLatest(SET_THING, watchSetThing)
    yield takeLatest(SET_TIME, watchSetTime)

    yield takeLatest(SAVE_MEETING, watchSaveMeeting)

    yield takeEvery(CHECKOUT, watchCheckout);

    yield takeEvery(RESET_STATE, resetStateSaga);
}

export default visitorSaga
