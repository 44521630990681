import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getHost, getEmployee, setHost } from '../../../store/actions';
import Select from 'react-select';
import _ from "lodash"
import CheckReload from '../../RouterURL/CheckReload';

const Host = (props) => {
    const navigate = useNavigate();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDeparment, setSelectedDeparment] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [note, setNote] = useState('');
    const [errors, setErrors] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const {
        companies,
        employees,
        onGetHost,
        onGetEmployee,

        onSetHost,
        host,
        setting,
    } = props;
    const params = useParams();
    useEffect(() => {
        onGetHost(params.customer_id);
    }, [onGetHost])

    useEffect(() => {
        const settingActive = setting.setting;
        if (settingActive.company_isActive === 0 &&
            settingActive.employee_isActive === 0 &&
            settingActive.hostNote_isActive === 0 &&
            settingActive.department_isActive === 0
        ) {
            return navigate("/meeting-time/" + params.customer_id)
        }
    }, [setting])

    useEffect(() => {
        if (!_.isEmpty(companies)) {
            let comps = [];
            companies.map(comp => {
                comps.push({
                    value: comp.id,
                    label: comp.company_name
                })
            });
            setCompanyList(comps);
        }
    }, [companies])

    const onChangeCompany = (val) => {
        setDepartmentList([]);
        setSelectedDeparment(null);

        setEmployeeList([]);
        setSelectedEmployee(null);

        setSelectedCompany(null);
        if (val) {
            setSelectedCompany(val);
            let company = companies.filter(comp => comp.id === val.value)
            let depts = [];
            company[0].department.map(dept => {
                depts.push({
                    value: dept.id,
                    label: dept.department_name
                })
            });
            setDepartmentList(depts);
            onSetHost({ ...host, company_id: val.value });
        }
    }

    const onChangeDepartment = (val) => {
        setEmployeeList([]);
        setSelectedEmployee(null);

        setSelectedDeparment(null);
        if (val) {
            let ids = {
                customer_id: params.customer_id,
                company_id: selectedCompany.value,
                department_id: val.value
            }
            setSelectedDeparment(val);
            onGetEmployee(ids)
            onSetHost({ ...host, department_id: val.value });
        }
    }

    useEffect(() => {
        if (!_.isEmpty(employees)) {
            let emps = [];
            employees.map(emp => {
                emps.push({
                    value: emp.id,
                    label: emp.first_name + " " + emp.last_name
                })
            });
            setEmployeeList(emps);
        }

    }, [employees])

    const handelValidate = () => {
        let errors = {};
        let isNext = true;
        if (setting.setting.company_isActive === 1) {
            if (!selectedCompany) {
                errors.company = {
                    type: "required",
                    message: "Vui lòng chọn công ty",
                };
                isNext = false;
            }
        }

        if (setting.setting.department_isActive === 1) {
            if (!selectedDeparment) {
                errors.department = {
                    type: "required",
                    message: "Vui lòng chọn phòng ban",
                };
                isNext = false;
            }
        }

        if (setting.setting.employee_isActive === 1) {
            if (!selectedEmployee) {
                errors.employee = {
                    type: "required",
                    message: "Vui lòng chọn nhân viên",
                };
                isNext = false;
            }
        }

        if (setting.setting.hostNote_isActive === 1) {
            if (!note) {
                errors.note = {
                    type: "required",
                    message: "Ghi chú người cần gập không được phép để trống",
                };
                isNext = false;
            }
        }

        setErrors(errors);
        return isNext;
    }

    const onChangeEmployee = (val) => {
        setSelectedEmployee(null);
        if (val) {
            onSetHost({ ...host, employee_id: val.value });
            setSelectedEmployee(val);
        }
    }

    const onChangeInput = (e) => {
        onSetHost({ ...host, note: e.target.value });
        setNote(e.target.value);
    }

    const onClickNext = () => {
        if (handelValidate()) {
            return navigate("/meeting-time/" + params.customer_id)
        }
    }

    return (
        <div className="card">
            <div className="card-body text-lg-start pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <a href={"/meeting-types/" + params.customer_id} style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</a>
                    </div>
                </div>
                <div className='text-center'>
                    <img width="200px" alt='' src='/images/hosts.jpg' />
                </div>
                {setting.setting.company_isActive === 1 && (
                    <div className='form-group'>
                        <label>Chọn công ty?</label>

                        <Select
                            name='cb_company'
                            id='cb_company'
                            onChange={onChangeCompany}
                            isClearable={true}
                            options={companyList}
                            placeholder={"Chọn công ty..."}
                            value={selectedCompany}
                        />
                        {errors.company && errors.company.type === "required" && (
                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.company.message}</span>
                        )}

                    </div>
                )}
                {setting.setting.department_isActive === 1 && selectedCompany && (
                    <div className='form-group'>
                        <label>Chọn phòng ban?</label>
                        <Select
                            name='cb_department'
                            id='cb_department'
                            onChange={onChangeDepartment}
                            isClearable={true}
                            isSearchable={true}
                            options={departmentList}
                            placeholder={"Chọn phòng ban..."}
                            value={selectedDeparment}
                        />
                        {errors.department && errors.department.type === "required" && (
                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.department.message}</span>
                        )}
                    </div>
                )}

                {setting.setting.employee_isActive === 1 && selectedCompany && selectedDeparment && (
                    <div className='form-group'>
                        <label>Chọn nhân viên?</label>
                        <Select
                            name='cb_employee'
                            id='cb_employee'
                            onChange={onChangeEmployee}
                            isClearable={true}
                            isSearchable={true}
                            options={employeeList}
                            placeholder={"Chọn nhân viên..."}
                            value={selectedEmployee}
                        />
                        {errors.employee && errors.employee.type === "required" && (
                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.employee.message}</span>
                        )}
                    </div>
                )}
                {setting.setting.department_isActive === 1 &&
                    (
                        <div className='form-group'>
                            <label>Ghi chú</label>
                            <input type="text" className='form-control' onChange={onChangeInput} defaultValue={note} name="txt_note" placeholder='Ghi chú...' />
                            {errors.note && errors.note.type === "required" && (
                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.note.message}</span>
                            )}
                        </div>
                    )
                }

                <div className='row'>
                    <div className='col-12'>
                        <button className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0" onClick={onClickNext}>
                            <span>Tiếp theo </span>
                            <i className="fa-solid fa-arrow-right me-1"></i>
                        </button>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </div>
    );
}

Host.propTypes = {
    companies: PropTypes.array,
    employees: PropTypes.array,
    onGetHost: PropTypes.func,
    onGetEmployee: PropTypes.func,
    host: PropTypes.object,
    setting: PropTypes.object,
}

const mapStateToProps = ({ hosts, setting, visitor }) => ({
    companies: hosts.companies,
    employees: hosts.employees,
    host: visitor.host,
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onGetHost: (customer_id) => dispatch(getHost(customer_id)),
    onGetEmployee: (ids) => dispatch(getEmployee(ids)),
    onSetHost: (data) => dispatch(setHost(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Host)