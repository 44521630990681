import { GET_COUNTRY_SUCCESS, GET_COUNTRY_FAIL } from "./actionTypes"

const INIT_STATE = {
    country: [],
    error: {},
}

const country = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                country: action.payload,
            }

        case GET_COUNTRY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default country
