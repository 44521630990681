import { eventChannel } from 'redux-saga';
import { call, put, take, takeEvery, select } from 'redux-saga/effects';

let socket = null;
let isConnected = false;
let websocket_url = "";
function createWebSocketChannel() {
    return eventChannel((emitter) => {
        socket = new WebSocket(websocket_url);

        // WebSocket event listeners
        socket.onopen = () => {
            console.log("Engine connected")
            isConnected = true;
            // emitter({ type: 'WEBSOCKET_OPEN' });
        };

        socket.onmessage = (event) => {
            // console.log(JSON.parse(event.data))
            emitter({ type: 'WEBSOCKET_MESSAGE', payload: JSON.parse(event.data) });
        };

        socket.onerror = (error) => {
            emitter({ type: 'WEBSOCKET_ERROR', payload: error });
        };

        socket.onclose = (e) => {
            isConnected = false;
            console.log('Connection is closed unexpectedly. Reconnect will be attempted in 3 second.', e.reason);
            setTimeout(() => {
                createWebSocketChannel();
            }, 3000);
            emitter({ type: 'WEBSOCKET_CLOSE' });
        };

        // Return unsubscribe function
        return () => {
            socket.close();
        };
    });
}

// Saga to handle WebSocket events
function* handleWebSocket({ payload: ws_url }) {
    websocket_url = ws_url
    // websocket_url = "wss://qi00017.qior.com.vn/pub/facerecognize"
    const channel = yield call(createWebSocketChannel);
    while (true) {
        const action = yield take(channel);

        // Dispatch action received from WebSocket
        yield put(action);
    }
}

// Saga to send a message when the specific component dispatches an action
function* sendMessageOnAction({ payload: data }) {
    if (isConnected && socket) {
        socket.send(JSON.stringify(data));
    }
}

export default function* websocketSaga() {
    yield takeEvery('WEBSOCKET_OPEN', handleWebSocket);
    yield takeEvery('SEND_WEBSOCKET_MESSAGE', sendMessageOnAction);
}