import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_MEETING_TYPE } from "./actionTypes"
import { getMeetingTypeSuccess, getMeetingTypeFail } from "./actions"

//Include Both Helper File with needed methods
import { getMeetingType } from "../../api/meeting-types"

function* fetchMeetingType({ customer_id }) {
    try {
        const response = yield call(getMeetingType, customer_id)
        yield put(getMeetingTypeSuccess(response.data))
    } catch (error) {
        yield put(getMeetingTypeFail(error))
    }
}

function* meetingTypeSaga() {
    yield takeEvery(GET_MEETING_TYPE, fetchMeetingType)
}

export default meetingTypeSaga
