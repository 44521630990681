import { GET_MEETING_TYPE_SUCCESS, GET_MEETING_TYPE_FAIL } from "./actionTypes"

const INIT_STATE = {
    data: [],
    error: {},
    loading: true,
}

const meeting_types = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MEETING_TYPE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            }

        case GET_MEETING_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default meeting_types
