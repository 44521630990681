import {
    GET_VISITOR_BY_FACE_SUCCESS,
    GET_VISITOR_BY_FACE_FAIL,

    GET_VISITOR_BY_QR_SUCCESS,
    GET_VISITOR_BY_QR_FAIL,

    UPDATE_VISITOR_SUCCESS,
    UPDATE_VISITOR_FAIL,

    STORE_VISITOR_SUCCESS,
    STORE_VISITOR_FAIL,

    SET_HOST_SUCCESS,
    SET_IMAGE_SUCCESS,
    SET_MEETINGTYPE_SUCCESS,
    SET_NDA_SUCCESS,
    SET_THING_SUCCESS,
    SET_TIME_SUCCESS,
    SET_MODULE_SUCCESS,

    SAVE_MEETING_SUCCESS,
    SAVE_MEETING_FAIL,

    CHECKOUT_SUCCESS,
    CHECKOUT_FAIL

} from "./actionTypes"

const INIT_STATE = {
    data: {},
    status: 0,
    message: {},
    loading: true,
    error: {},
    dataFromQR: {},

    // Meeting
    module: '',
    imageMeeting: {
        register: '',
        detail: ''
    },
    meetingType: {},
    host: {
        company_id: '',
        department_id: '',
        employee_id: '',
        note: ''
    },
    meetingTime: {
        date: '',
        timeIn: '',
        timeOut: ''
    },
    thingCarrying: {
        description: '',
        image: ''
    },
    nda: '',

    checkout_message: {},
}

const visitor = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_STATUS':
            return {
                ...state,
                status: action.payload,
            }

        case GET_VISITOR_BY_FACE_SUCCESS:
            return {
                ...state,
                data: action.payload.visitor,
                status: action.payload.status,
                loading: false
            }


        case GET_VISITOR_BY_FACE_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_VISITOR_BY_QR_SUCCESS:
            return {
                ...state,
                data: action.payload.visitor,
                status: action.payload.status,
                loading: false
            }


        case GET_VISITOR_BY_QR_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case STORE_VISITOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }

        case STORE_VISITOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_VISITOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }

        case UPDATE_VISITOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_HOST_SUCCESS:
            return {
                ...state,
                host: { ...state.host, ...action.payload },
            }

        case SET_IMAGE_SUCCESS:
            return {
                ...state,
                imageMeeting: { ...state.imageMeeting, ...action.payload },
            }

        case SET_MEETINGTYPE_SUCCESS:
            return {
                ...state,
                meetingType: action.payload,
            }

        case SET_NDA_SUCCESS:
            return {
                ...state,
                nda: action.payload,
            }

        case SET_THING_SUCCESS:
            return {
                ...state,
                thingCarrying: { ...state.thingCarrying, ...action.payload },
            }

        case SET_TIME_SUCCESS:
            return {
                ...state,
                meetingTime: { ...state.meetingTime, ...action.payload },
            }

        case SET_MODULE_SUCCESS:
            return {
                ...state,
                module: action.payload,
            }

        case SAVE_MEETING_SUCCESS:
            return {
                ...state,
                message: action.payload,
            }

        case SAVE_MEETING_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case CHECKOUT_SUCCESS:
            return {
                ...state,
                checkout_message: action.payload,
            }

        case CHECKOUT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case "RESET_DEFAULT":
            return INIT_STATE; // Reset the state to its initial values

        case "SET_DATA_FROM_QR":
            return {
                ...state,
                dataFromQR: action.payload,
            }

        default:
            return state
    }
}

export default visitor
