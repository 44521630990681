import { GET_COUNTRY, GET_COUNTRY_SUCCESS, GET_COUNTRY_FAIL } from "./actionTypes"

export const getCountry = () => ({
    type: GET_COUNTRY
})


export const getCountrySuccess = country => ({
    type: GET_COUNTRY_SUCCESS,
    payload: country,
})

export const getCountryFail = error => ({
    type: GET_COUNTRY_FAIL,
    payload: error,
})
