import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import CheckReload from '../RouterURL/CheckReload';
import _ from "lodash"

const Result = (props) => {
    const { checkoutMessage } = props;
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const params = useParams();

    useEffect(() => {
        if (checkoutMessage?.status_code === 200) {
            setSuccess(true)
        }
    }, [checkoutMessage])

    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
            localStorage.clear();
            window.location.href = url_home;
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(redirectTimeout);
    }, []);

    const backToHome = () => {
        let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
        localStorage.clear();
        window.location.href = url_home;
    }


    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='text-center'>
                        {success ? (
                            <>
                                <h5 className='mt-4'>CHECKOUT THÀNH CÔNG</h5>
                                <p>Vui lòng nhận lại giấy tờ trước khi ra về!</p>
                                <img width="100px" className='mb-3' alt='' src='/images/success.png' />
                            </>
                        ) : (
                            <>
                                <h5 className='mt-4'>CHECKOUT THẤT BẠI</h5>
                                <p>Không tìm thấy thông tin! Vui lòng đăng ký mới</p>
                                <img width="100px" className='mb-3' alt='' src='/images/close.png' />
                            </>
                        )}
                        <div className='form-group'>
                            <button onClick={backToHome} className='btn btn-primary'>OK</button>
                        </div>

                    </div>
                </div>
                <CheckReload></CheckReload>
            </div >
        </>
    );
}

Result.propTypes = {
    checkoutMessage: PropTypes.any,
}

const mapStateToProps = ({ visitor }) => ({
    checkoutMessage: visitor.checkout_message
})

export default connect(mapStateToProps, null)(Result);

