import React, { useState } from 'react';
//import { useTranslation } from 'react-i18next';

function Header() {

/*     const { t, i18n } = useTranslation();

    function handleClick(lang) {
        i18n.changeLanguage(lang);
    } */

    return (
        <>
            <div className="page-header position-relative" style={{ backgroundImage: "url('/assets/img/pricing-header-bg.jpg')", backgroundSize: "cover" }}>
                <span className="mask bg-gradient-primary opacity-6"></span>
                <div className="container-fluid pb-lg-9 pb-8 pt-7 postion-relative">
                    <div className="row mt-4">
                        <div className="col-md-6 mx-auto text-center border-radius-2xl">
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent mt-4">
                <div className="container-fluid bg-white border-radius-xl ps-2 pe-0">
                    <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 m-2 text-white" href="!#">
                        <img src="/assets/img/logos/Qiosk.png" width="100px" alt="" />
                    </a>
                    <div className="dropdown mx-4 mt-2">
{/*                         <a href="!#" className="dropdown-toggle text-dark" data-bs-toggle="dropdown" id="changeLang">
                            <i className="fa-solid fa-earth-asia"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="changeLang">
                            <li>
                                <button className="dropdown-item" onClick={() => handleClick('vi')}>
                                    <img src="/assets/img/icons/flags/1x1/vn.svg" width="10%" alt="" /> Việt Nam
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => handleClick('en')}>
                                    <img src="/assets/img/icons/flags/1x1/us.svg" width="10%" alt="" /> United State
                                </button>
                            </li>
                        </ul> */}
                    </div>

                </div>
            </nav>
        </>

    );
}

export default Header;