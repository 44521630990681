import axios from "axios"
import { post, del, get, put, postFormData } from "./api_helper"

import * as url from "./url_helper"

export const getVisitorByQR = async params => {

    let bodyFormData = new FormData();
    bodyFormData.append('code', params.data);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    };
    return await postFormData(`${url.GET_VISITOR}/${params.customer_id}`, bodyFormData, config);
}

export const storeVisitor = async params => {
    const dataObj = params.data
    let bodyFormData = new FormData();
    bodyFormData.append('device_id', dataObj.device_id);
    // Fill data visitor to form
    bodyFormData.append('visitor_id', !dataObj.visitor.id ? '' : dataObj.visitor.id);
    bodyFormData.append('txt_name', dataObj.visitor.visitor_name);
    bodyFormData.append('txt_phone_number', dataObj.visitor.phone_number);
    bodyFormData.append('dt_birth', !dataObj.visitor.birth ? '' : dataObj.visitor.birth);
    bodyFormData.append('txt_email', !dataObj.visitor.email ? '' : dataObj.visitor.email);
    bodyFormData.append('txt_identity', !dataObj.visitor.identity_id ? '' : dataObj.visitor.identity_id);
    bodyFormData.append('dt_issuedOn', !dataObj.visitor.issued_on ? '' : dataObj.visitor.issued_on);
    bodyFormData.append('txt_nationality', !dataObj.visitor.nationality ? '' : dataObj.visitor.nationality);
    bodyFormData.append('txt_company', !dataObj.visitor.company ? '' : dataObj.visitor.company);
    bodyFormData.append('txt_position', !dataObj.visitor.position ? '' : dataObj.visitor.position);
    bodyFormData.append('txt_address', !dataObj.visitor.address ? '' : dataObj.visitor.address);

    // Choose visitor type
    if (dataObj.visitor_type) {
        bodyFormData.append('cb_purpose', dataObj.visitor_type);
    }
    // Choose detail meeting
    if (dataObj.meeting_time) {
        bodyFormData.append('dt_dateVisit', dataObj.meeting_time.date);
        bodyFormData.append('dt_from', dataObj.meeting_time.timeIn);
        bodyFormData.append('dt_to', dataObj.meeting_time.timeOut);
    }

    if (dataObj.hosts) {
        bodyFormData.append('cb_person', dataObj.hosts.employee_id);
        bodyFormData.append('cb_company', dataObj.hosts.company_id);
        bodyFormData.append('cb_department', dataObj.hosts.department_id);
        bodyFormData.append('txt_note', dataObj.hosts.note);
    }


    // Sign with face
    bodyFormData.append('img', dataObj.image);
    bodyFormData.append('platform', "web");
    // Thing carrying
    if (dataObj.thing_carrying) {
        bodyFormData.append('txt_description', dataObj.thing_carrying.description);
        bodyFormData.append('img_thing', dataObj.thing_carrying.image);
    }

    // Signature       
    if (dataObj.nda) {
        bodyFormData.append('sign', dataObj.nda);
    }

    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    };
    // console.log(bodyFormData);
    return await postFormData(`${url.STORE_MEETING}/${params.customer_id}`, bodyFormData, config);
}

export const getVisitorByFR = async params => {
    // console.log(params);
    return await get(`${url.GET_VISITOR_BY_FACE}/${params.customer_id}/${params.visitor_id}`);
}

export const checkoutByQR = async params => {

    let bodyFormData = new FormData();
    bodyFormData.append('code', params.code);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    };
    return await postFormData(`${url.CHECKOUT}/${params.customer_id}`, bodyFormData, config);
}

export const checkoutByFace = async params => {
    let bodyFormData = new FormData();
    bodyFormData.append('visitor_id', params.visitor_id);
    bodyFormData.append('code', params.code);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    };
    return await postFormData(`${url.CHECKOUT}/${params.customer_id}`, bodyFormData, config);
}
