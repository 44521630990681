import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import moment from 'moment';
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { getCountry, storeVisitor } from '../../../store/actions';
import CheckReload from '../../RouterURL/CheckReload';


const New = (props) => {
    registerLocale("vi", vi);
    const { visitor, country, onGetCountry, onNewVisitor, dataFromQR } = props;
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [birthError, setBirthError] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [issuedDate, setIssuedDate] = useState('');
    const [chooseCountry, setChooseCountry] = useState('');

    const params = useParams();
    const navigate = useNavigate();

    function isOver18(dateOfBirth) {
        // find the date 18 years ago
        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 16);
        // check if the date of birth is before that date

        return dateOfBirth >= date18YrsAgo;
    }

    useEffect(() => {
        if (!_.isEmpty(dataFromQR)) {
            reset({
                txt_identity: dataFromQR.identity_id,
                txt_name: dataFromQR.visitor_name,
                txt_address: dataFromQR.address,
            });
            setBirthDate(new Date(dataFromQR.birth));
            setIssuedDate(new Date(dataFromQR.issued_on))
        }
        onGetCountry();
    }, [reset, onGetCountry, setBirthDate, setIssuedDate, dataFromQR])

    const onSubmit = data => {
        if (isOver18(moment(birthDate, 'DD-MM-YYYY')._d)) {
            setBirthError("Date of birth must be greater than 16");
        }
        else {
            setBirthError('');
            let visitorNew = {
                ...visitor,
                visitor_name: data.txt_name,
                phone_number: data.txt_phone_number,
                email: data.txt_email,
                nationality: _.isEmpty(chooseCountry) ? '' : chooseCountry.value,
                birth: birthDate === '' ? '' : moment(birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                identity_id: data.txt_identity,
                issued_on: issuedDate === '' ? '' : moment(issuedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                address: data.txt_address,
                company: data.txt_company === '' ? '-' : data.txt_company,
                position: data.txt_position === '' ? '-' : data.txt_position,
            }
            // setIsNew(true);

            onNewVisitor(visitorNew);
            const customer_id = params.customer_id
            return navigate(`/hosts/${customer_id}`);
        }
    };


    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/checkin-module/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>
                            <Link to={'/profile/new/get-info-from-qr/' + params.customer_id}>
                                <span style={{ fontSize: "23px" }} className='text-dark float-end m-2'><i className="fa-solid fa-qrcode mt-1"></i></span>
                            </Link>
                        </div>
                    </div>
                    <div className="row align-items-center mt-3">
                        <div className="col-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <p className='text-dark' style={{ fontSize: "13px" }}>
                                    <span className='font-weight-bold text-warning'>Lưu ý: </span>
                                    Các ô có dấu <span className='text-danger'>*</span> màu đỏ yêu cầu bắt buộc phải nhập. Còn lại có thể nhập hoặc không
                                </p>
                                <div className='row'>
                                    <div className="form-group col-7 col-xl-7">
                                        <label className='text-dark'>Họ và tên <span className='text-danger'>*</span></label>
                                        <input
                                            aria-invalid={errors.txt_name ? "true" : "false"}
                                            {...register('txt_name', { required: true })}
                                            className='form-control'
                                            placeholder={"Vui lòng nhập tên..."}
                                        />
                                        {errors.txt_name && errors.txt_name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên không được để trống</span>
                                        )}
                                    </div>
                                    <div className="form-group col-5 col-xl-5">
                                        <label className='text-dark'>Số điện thoại <span className='text-danger'>*</span></label>
                                        <input
                                            aria-invalid={errors.txt_phone_number ? "true" : "false"}
                                            {...register('txt_phone_number', {
                                                required: true,
                                                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
                                            })}
                                            className='form-control'
                                            placeholder={"Số điện thoại..."}
                                        />
                                        {errors.txt_phone_number && errors.txt_phone_number.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Số điện thoại không được để trống</span>
                                        )}
                                        {errors.txt_phone_number && errors.txt_phone_number.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Số điện thoại phải là chữ số và phải có đủ 10 chữ số</span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='text-dark'>Email</label>
                                    <input
                                        aria-invalid={errors.txt_email ? "true" : "false"}
                                        {...register("txt_email",
                                            {
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })}
                                        name="txt_email"
                                        className='form-control'
                                        placeholder={"abc@example.com..."}
                                    />
                                    {errors.txt_email && errors.txt_email.type === "pattern" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không hợp lệ</span>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className="form-group col-7">
                                        <label className='text-dark'>Quốc tịch</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            onChange={(value) => setChooseCountry(value)}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={country}
                                            placeholder={"Chọn..."}
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label className='text-dark'>Ngày sinh</label>
                                        <DatePicker
                                            locale="vi"
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={"Ngày/tháng/năm"}
                                            selected={birthDate}
                                            onChange={(date) => setBirthDate(date)}
                                        ></DatePicker>
                                        {birthError !== '' && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Ngày sinh phải lớn hơn 16 tuổi</span>
                                        )}
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className="form-group col-7">
                                        <label className='text-dark'>Chứng minh nhân dân</label>
                                        <input
                                            {...register('txt_identity')}
                                            className='form-control'
                                            placeholder={"CMND/CCCD/Passport"}
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label className='text-dark'>Ngày cấp</label>
                                        <DatePicker
                                            locale="vi"
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={"Ngày/tháng/năm"}
                                            selected={issuedDate}
                                            onChange={(date) => setIssuedDate(date)}
                                        ></DatePicker>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='text-dark'>Địa chỉ</label>
                                    <input
                                        {...register('txt_address')}
                                        className='form-control'
                                        placeholder={"Địa điểm..."}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className='text-dark'>Công ty</label>
                                    <input
                                        {...register('txt_company')}
                                        className='form-control'
                                        placeholder={"Công ty..."}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className='text-dark'>Chức vụ</label>
                                    <input
                                        {...register('txt_position')}
                                        className='form-control'
                                        placeholder={"Chức vụ..."}
                                    />
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12 text-center'>
                                        <button type="submit" style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>Tiếp theo</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <CheckReload></CheckReload>
        </>
    );
}

New.propTypes = {
    visitor: PropTypes.object,
    onGetCountry: PropTypes.func,
    country: PropTypes.array,
    onNewVisitor: PropTypes.func,
    dataFromQR: PropTypes.object
}

const mapStateToProps = ({ visitor, country }) => {
    return {
        visitor: visitor.data,
        country: country.country,
        dataFromQR: visitor.dataFromQR
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCountry: () => dispatch(getCountry()),
    onNewVisitor: (visitorNew) => dispatch(storeVisitor(visitorNew))
})

export default connect(mapStateToProps, mapDispatchToProps)(New);
