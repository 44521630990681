import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { getCountry, updateVisitor } from '../../../store/actions';
import CheckReload from '../../RouterURL/CheckReload';


const Edit = (props) => {
    registerLocale("vi", vi);
    const { visitor, country, onGetCountry, selectedCountry, onUpdateVisitor } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [birthDate, setBirthDate] = useState(visitor?.birth || '');
    const [issuedDate, setIssuedDate] = useState(visitor?.issued_on || '');
    const [chooseCountry, setChooseCountry] = useState(null);

    const [isUpdate, setIsUpdate] = useState(false);

    const params = useParams();

    useEffect(() => {
        onGetCountry();
    }, [onGetCountry])

    useEffect(() => {
        setChooseCountry(selectedCountry);
    }, [selectedCountry])

    const onSubmit = data => {
        let visitorEdit = {
            ...visitor,
            visitor_name: data.txt_name,
            phone_number: data.txt_phone_number,
            email: data.txt_email,
            nationality: chooseCountry.value,
            birth: moment(birthDate, 'DD-MM-YYYY')._i,
            identity_id: data.txt_identity,
            issued_on: moment(issuedDate, 'DD-MM-YYYY')._i,
            address: data.txt_address,
            company: data.txt_company === '' ? '-' : data.txt_company,
            position: data.txt_position === '' ? '-' : data.txt_position,
        }
        setIsUpdate(true);
        onUpdateVisitor(visitorEdit);
    };


    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/profile/show/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>

                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-lg-12 col-12">
                            <div className='text-center'>
                                <img className="border-radius-lg shadow-sm" style={{ width: "100px", objectFit: "fill", borderRadius: '50%' }} id="wizardPicturePreview"
                                    src={visitor?.images === null ? "/images/gambar-user-png-33.png" : "https://portal.qior.com.vn/" + visitor?.images} alt='' />
                                <p className='text-dark m-0 mt-2 font-weight-bold' style={{ fontSize: "14px" }}> {visitor?.visitor_name}</p>
                                <p className='text-dark' style={{ fontSize: "12px" }}>{visitor?.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <p className='text-dark' style={{ fontSize: "13px" }}>
                                    <span className='font-weight-bold text-warning'>Lưu ý: </span>
                                    Các ô có dấu <span className='text-danger'>*</span> màu đỏ yêu cầu bắt buộc phải nhập. Còn lại có thể nhập hoặc không
                                </p>
                                <div className='row'>
                                    <div className="form-group col-7 col-xl-7">
                                        <label className='text-dark'>Họ và tên <span className='text-danger'>*</span></label>
                                        <input
                                            aria-invalid={errors.txt_name ? "true" : "false"}
                                            {...register('txt_name', { required: true })}
                                            className='form-control'
                                            placeholder={"Vui lòng nhập tên..."}
                                            defaultValue={visitor?.visitor_name}
                                        />
                                        {errors.txt_name && errors.txt_name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên không được để trống</span>
                                        )}
                                    </div>
                                    <div className="form-group col-5 col-xl-5">
                                        <label className='text-dark'>Số điện thoại <span className='text-danger'>*</span></label>
                                        <input
                                            aria-invalid={errors.txt_phone_number ? "true" : "false"}
                                            {...register('txt_phone_number', {
                                                required: true,
                                                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
                                            })}
                                            className='form-control'
                                            placeholder={"Số điện thoại..."}
                                            defaultValue={visitor?.phone_number}
                                        />
                                        {errors.txt_phone_number && errors.txt_phone_number.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Số điện thoại không được để trống</span>
                                        )}
                                        {errors.txt_phone_number && errors.txt_phone_number.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Số điện thoại phải là chữ số và phải có đủ 10 chữ số</span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='text-dark'>Email</label>
                                    <input
                                        aria-invalid={errors.txt_email ? "true" : "false"}
                                        {...register("txt_email",
                                            {
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })}
                                        name="txt_email"
                                        className='form-control'
                                        defaultValue={visitor?.email}
                                    />
                                    {errors.txt_email && errors.txt_email.type === "pattern" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không hợp lệ</span>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className="form-group col-7">
                                        <label className='text-dark'>Quốc tịch</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={chooseCountry}
                                            onChange={(value) => setChooseCountry(value)}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={country}
                                            placeholder={"Chọn..."}
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label className='text-dark'>Ngày sinh</label>
                                        <DatePicker
                                            locale="vi"
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={"Ngày/tháng/năm"}
                                            selected={birthDate ? moment(birthDate, 'DD-MM-YYYY')._d : ''}
                                            onChange={(date) => setBirthDate(date)}
                                        ></DatePicker>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className="form-group col-7">
                                        <label className='text-dark'>Chứng minh nhân dân</label>
                                        <input
                                            {...register('txt_identity')}
                                            className='form-control'
                                            defaultValue={visitor?.identity_id}
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label className='text-dark'>Ngày cấp</label>
                                        <DatePicker
                                            locale="vi"
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={"Ngày/tháng/năm"}
                                            selected={issuedDate ? moment(issuedDate, 'DD-MM-YYYY')._d : ''}
                                            onChange={(date) => setIssuedDate(date)}
                                        ></DatePicker>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='text-dark'>Địa chỉ</label>
                                    <input
                                        {...register('txt_address')}
                                        className='form-control'
                                        defaultValue={visitor?.address}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className='text-dark'>Công ty</label>
                                    <input
                                        {...register('txt_company')}
                                        className='form-control'
                                        defaultValue={visitor?.company}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className='text-dark'>Chức vụ</label>
                                    <input
                                        {...register('txt_position')}
                                        className='form-control'
                                        defaultValue={visitor?.position}
                                    />
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12 text-center'>
                                        {!isUpdate && (
                                            <button type="submit" style={{ fontSize: "15px" }} className='btn bg-gradient-success form-control'>Cập nhật</button>
                                        )}
                                        {isUpdate && (
                                            <Link to={"/meeting-types/" + params.customer_id}>
                                                <button style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>
                                                    Tiếp tục
                                                    <i className="fa-solid fa-arrow-right"></i>
                                                </button>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <CheckReload></CheckReload>
        </>
    );
}

Edit.propTypes = {
    visitor: PropTypes.object,
    onGetCountry: PropTypes.func,
    country: PropTypes.array,
    selectedCountry: PropTypes.object,
    onUpdateVisitor: PropTypes.func
}

const mapStateToProps = ({ visitor, country }) => {
    let selectedCountry = null;
    country.country.map(ct => {
        if (visitor?.nationality === ct.label || visitor?.nationality === ct.value) {
            selectedCountry = { value: ct.value, label: ct.label };
        }
    })

    return {
        visitor: visitor.data,
        country: country.country,
        selectedCountry: selectedCountry
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCountry: () => dispatch(getCountry()),
    onUpdateVisitor: (visitorEdit) => dispatch(updateVisitor(visitorEdit))
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
