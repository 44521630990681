import { GET_MEETING_TYPE, GET_MEETING_TYPE_FAIL, GET_MEETING_TYPE_SUCCESS } from "./actionTypes"

export const getMeetingType = (customer_id) => ({
    type: GET_MEETING_TYPE,
    customer_id
})

export const getMeetingTypeSuccess = data => ({
    type: GET_MEETING_TYPE_SUCCESS,
    payload: data,
})

export const getMeetingTypeFail = error => ({
    type: GET_MEETING_TYPE_FAIL,
    payload: error,
})
