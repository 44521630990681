import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendWebsocketMessage, checkout, setImage } from '../../../store/actions'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import _ from "lodash"
import CheckReload from '../../RouterURL/CheckReload';

const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 224;

const CaptureImage = (props) => {

    const [imageSrc, setImageSrc] = useState('');
    const [isCapture, setIsCapture] = useState(false);
    const [isProcess, setIsProcess] = useState(false);

    const navigate = useNavigate();
    const webcamRef = React.useRef(null);
    const {
        onSendWebsocket,
        onCheckout,
        messagesRec,
        checkoutMessage,
        onSetImage
    } = props;
    const params = useParams();
    const capture = () => {
        const image = webcamRef.current.getScreenshot();
        setImageSrc(image);
        setIsCapture(true);
        setIsProcess(true)
        let message = { "command": "face_recognize", "image": image };
        onSendWebsocket(message);
    }

    const retake = () => {
        setImageSrc("");
        setIsCapture(false);
        setIsProcess(false)
    };

    useEffect(() => {

        if (messagesRec?.result === false) {
            if (messagesRec?.message === 'Spoofing face') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Phát hiện ảnh giả mạo!",
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Không tìm thấy dữ liệu!",
                });
            }
            setIsProcess(false)
        } else {
            onSetImage({
                register: imageSrc,
                detail: imageSrc
            })
            let visitor_id = messagesRec?.message?.map((face) => face.visitors);
            let user_id = messagesRec?.message?.map((face) => face.userid);
            if (visitor_id && user_id) {
                let data = { customer_id: params.customer_id, visitor_id: visitor_id[0], code: 'no-id', module: 'fr' }
                onCheckout(data); // Dòng này để data check out
            }
            setIsProcess(false)
        }
    }, [messagesRec])

    useEffect(() => {
        if (imageSrc) {
            if (_.isEmpty(checkoutMessage) || checkoutMessage === '') {
                swal({
                    icon: "error",
                    title: "Lỗi 205!",
                    text: 'Không tìm thấy dữ liệu. Bạn chưa đăng ký gặp!!!',
                    type: "error",
                    timer: 5000,
                    showConfirmButton: false
                });
                setImageSrc('')
            } else if (checkoutMessage?.status_code === 200) {
                setImageSrc('')
                swal({
                    icon: "success",
                    title: "Success!",
                    text: "Checkout thành công. Vui lòng nhận lại giấy tờ!",
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                }).then(function () {
                    let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
                    localStorage.clear();
                    window.location.href = url_home;
                });
            }
        }
    }, [checkoutMessage, imageSrc])



    return (
        <>
            <div className="card">
                <div className="card-body text-center">
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/checkout-module/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>
                        </div>
                    </div>
                    <h5 className="text-dark mt-3">Chụp ảnh khuôn mặt</h5>
                    <img className="mt-3" width="30%" src="/images/selfie.png" alt='' />

                    {isCapture && (
                        <div className="row justify-content-center">
                            <div className="form-group">
                                <TakePhoto imgsrc={imageSrc} />
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <button type="button" className="btn bg-gradient-info form-control" onClick={retake}><i className="fa-solid fa-clock-rotate-left"></i> Chụp lại</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isCapture && (
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <p className="text-warning">Vui lòng tháo khẩu trang!</p>
                                <Webcam className="border border-secondary rounded" width="100%" audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                                <br />
                                <div className="form-group">
                                    <button type="button" className="btn bg-gradient-primary form-control" onClick={capture}><i className='fa-solid fa-camera'></i> Chụp ảnh</button>
                                </div>

                            </div>
                        </div>

                    )}
                    {isProcess && <SemipolarLoading color={'#33D7FF'} />}
                </div>
                <CheckReload></CheckReload>
            </div>


        </>
    );
};

const TakePhoto = (props) => {
    const imgsrc = props.imgsrc;
    return (
        <div>
            <img className='mt-3 rounded' width="100%" src={imgsrc} alt="" />
        </div>
    );
}





CaptureImage.propTypes = {
    onSendWebsocket: PropTypes.func,
    messagesRec: PropTypes.object,
    visitor: PropTypes.any,
    onSetImage: PropTypes.func,
    onCheckout: PropTypes.func,
    checkoutMessage: PropTypes.any
}

const mapStateToProps = ({ wsSocket, visitor }) => ({
    messagesRec: wsSocket.messages,
    visitor: visitor.data,
    visitorStatusCode: visitor.status,
    checkoutMessage: visitor.checkout_message

});

const mapDispatchToProps = dispatch => ({
    onSendWebsocket: (data) => (dispatch(sendWebsocketMessage(data))),
    onSetImage: (data) => dispatch(setImage(data)),
    onCheckout: (data) => dispatch(checkout(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CaptureImage);