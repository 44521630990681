import { WEBSOCKET_MESSAGE, WEBSOCKET_OPEN, WEBSOCKET_CLOSE, WEBSOCKET_ERROR, SET_DEFAULT_MESSAGE } from "./actionTypes"

const INIT_STATE = {
    messages: {},
    error: {},
}

const WebSocket = (state = INIT_STATE, action) => {
    switch (action.type) {

        case WEBSOCKET_OPEN:
            return {
                ...state,
            };

        case WEBSOCKET_CLOSE:
            return {
                ...state,
            };

        case WEBSOCKET_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case WEBSOCKET_MESSAGE:
            return {
                ...state,
                messages: action.payload,
            };

        case SET_DEFAULT_MESSAGE:
            return INIT_STATE

        default:
            return state;
    }
};

export default WebSocket;
