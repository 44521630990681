import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getMeetingType, setMeeting } from '../../../store/actions';
import Select from 'react-select';
import _ from "lodash";
import CheckReload from '../../RouterURL/CheckReload';

const MeetingType = (props) => {
    const [selectedType, setSelectedType] = useState('');
    const [optionMeetingTypes, setOptionMeetingTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { meetingTypes, onGetMeetingType, loading, onSetMeeting, visitor, setting } = props;
    const params = useParams();
    useEffect(() => {
        onGetMeetingType(params.customer_id);
    }, [onGetMeetingType])

    useEffect(() => {
        if (setting.setting.checkin_one_step === 1) {
            return navigate("/nda/" + params.customer_id)
        }
    }, [setting])

    useEffect(() => {
        if (!_.isEmpty(meetingTypes)) {
            let types = [];
            meetingTypes.map(type => {
                types.push({
                    value: type.id,
                    label: type.description
                })
            });
            setOptionMeetingTypes(types);
        }
    }, [meetingTypes])

    const handleChangeMeetingType = (val) => {
        setSelectedType(null);
        onSetMeeting({})
        if (val) {
            const type = meetingTypes.filter(type => val.value === type.id);
            setSelectedType(val);
            onSetMeeting(type[0])
        }
    }

    const handelValidate = () => {
        let errors = {};
        let isNext = true;

        if (!selectedType) {
            errors.type = {
                type: "required",
                message: "Vui lòng chọn mục đích gặp",
            };
            isNext = false;
        }
        setErrors(errors);
        return isNext;
    }

    const handleNext = () => {
        if (handelValidate()) {
            if (!_.isEmpty(visitor)) {
                return navigate("/hosts/" + params.customer_id)
            } else {
                return navigate("/profile/new/" + params.customer_id)
            }

        }
    }

    return (
        <div className="card">
            <div className="card-body text-lg-start text-center pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <Link to={'/checkin-module/' + params.customer_id}>
                            <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                        </Link>
                    </div>
                </div>
                <div className='text-center'>
                    <h5 className='mt-4'>Chọn mục đích gặp</h5>

                    <img width="200px" alt='' src='/images/purpose.jpg' />

                    <div className='form-group mt-3'>
                        {loading === false &&
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={handleChangeMeetingType}
                                isClearable={true}
                                isSearchable={true}
                                options={optionMeetingTypes}
                                placeholder={"Chọn mục đích gặp..."}
                            />
                        }
                        {errors.type && errors.type.type === "required" && (
                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.type.message}</span>
                        )}

                    </div>

                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button onClick={handleNext} className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0">
                            <span>Tiếp theo </span>
                            <i className="fa-solid fa-arrow-right me-1"></i>

                        </button>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </div>
    );
}

MeetingType.propTypes = {
    meetingTypes: PropTypes.array,
    loading: PropTypes.bool,
    onGetMeetingType: PropTypes.func,
    onSetMeeting: PropTypes.func,
    visitor: PropTypes.object,
    setting: PropTypes.object
}

const mapStateToProps = ({ meeting_types, visitor, setting }) => ({
    meetingTypes: meeting_types.data,
    loading: meeting_types.loading,
    visitor: visitor.data,
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onGetMeetingType: (customer_id) => dispatch(getMeetingType(customer_id)),
    onSetMeeting: (data) => dispatch(setMeeting(data)),


})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingType)