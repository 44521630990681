import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects"

//Include Both Helper File with needed methods
import { getCountry } from "../../api/country"
// Crypto Redux States
import { GET_COUNTRY } from "./actionTypes"
import { getCountrySuccess, getCountryFail } from "./actions"

const formatCountry = (countries) => {
    let country_list = [];
    countries.map((country) => (
        country_list.push({
            value: country.code,
            label: country.name
        })
    ));
    return country_list;
}

function* fetchCountry() {
    try {
        const response = yield call(getCountry)
        let countries = formatCountry(response);
        yield put(getCountrySuccess(countries))

    } catch (error) {
        yield put(getCountryFail(error))
    }
}

function* countrySaga() {
    yield takeEvery(GET_COUNTRY, fetchCountry)
}

export default countrySaga
