/* WEBSOCKET */
export const WEBSOCKET_OPEN = "WEBSOCKET_OPEN"
export const WEBSOCKET_MESSAGE = "WEBSOCKET_MESSAGE"
export const SEND_WEBSOCKET_MESSAGE = "SEND_WEBSOCKET_MESSAGE"
export const WEBSOCKET_ERROR = "WEBSOCKET_ERROR"
export const WEBSOCKET_CLOSE = "WEBSOCKET_CLOSE"

export const SET_DEFAULT_MESSAGE = "SET_DEFAULT_MESSAGE"




