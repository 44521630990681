import { all, fork } from "redux-saga/effects"

//public
import settingSaga from "./setting/saga"
import visitorSaga from "./visitor/saga"
import countrySaga from "./country/saga"
import meetingTypeSaga from "./meeting-types/saga"
import hosts from "./hosts/saga"
import websocketSaga from "./websocket/saga";


export default function* rootSaga() {
    yield all([
        //public
        fork(settingSaga),
        fork(visitorSaga),
        fork(countrySaga),
        fork(meetingTypeSaga),
        fork(hosts),
        fork(websocketSaga)
    ])
}
