import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_SETTING, SET_TYPE_REG } from "./actionTypes"
import { getSettingSuccess, getSettingFail, setTypeRegSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSettingByParams } from "../../api/setting"

function* fetchSetting({ params }) {
    try {
        const response = yield call(getSettingByParams, params)
        // console.log(response);

        yield put(getSettingSuccess(response))
    } catch (error) {
        yield put(getSettingFail(error))
    }
}

function* onSetTypeReg({ type, payload }) {
    yield put(setTypeRegSuccess(payload))
}

function* settingSaga() {
    yield takeEvery(GET_SETTING, fetchSetting)
    yield takeEvery(SET_TYPE_REG, onSetTypeReg)
}

export default settingSaga
