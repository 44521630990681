import axios from "axios"
import { post, del, get, put } from "./api_helper"

import * as url from "./url_helper"

export const getHost = async customer_id => {
    return await get(`${url.GET_HOST}/${customer_id}`)
}

export const getEmployee = async (data) => {
    return await get(`${url.GET_EMPLOYEE}/${data.customer_id}?company_id=${data.company_id}&department_id=${data.department_id}`)
}
