import * as faceapi from 'face-api.js';

// Load models and weights
export async function loadModels() {
    const MODEL_URL = process.env.PUBLIC_URL + '/models';
    await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
    await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
    // await faceapi.loadFaceRecognitionModel(MODEL_URL);
}

export async function getFullFaceDescription(blob, inputSize = 224) {
    // tiny_face_detector options
    let scoreThreshold = 0.5;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
        inputSize,
        scoreThreshold
    });
    const useTinyModel = true;

    // fetch image to api
    let img = await faceapi.fetchImage(blob);

    // detect all faces and generate full description from image
    // including landmark and descriptor of each face
    let fullDesc = await faceapi
        .detectAllFaces(img, OPTION)
        .withFaceLandmarks(useTinyModel)
    // .withFaceDescriptors();
    return fullDesc;
}


export async function extractFaceFromBox(blob, box) {
    const regionsToExtract = [
        new faceapi.Rect(box.x, box.y, box.width, box.height)
    ]
    let outputImage = "";

    let img = await faceapi.fetchImage(blob);

    let faceImages = await faceapi.extractFaces(img, regionsToExtract)

    if (faceImages.length === 0) {
        console.log('Face not found')
    }
    else {
        faceImages.forEach(cnv => {
            outputImage = cnv.toDataURL();
        })
        return outputImage
    }
}                       
