import {
    WEBSOCKET_MESSAGE,
    SEND_WEBSOCKET_MESSAGE,
    WEBSOCKET_OPEN,
    WEBSOCKET_CLOSE,
    WEBSOCKET_ERROR,
    SET_DEFAULT_MESSAGE
}
    from "./actionTypes"

export const websocketOpen = (ws_url) => ({
    type: WEBSOCKET_OPEN,
    payload: ws_url
})

export const websocketClose = () => ({
    type: WEBSOCKET_CLOSE,
})

export const websocketError = (error) => ({
    type: WEBSOCKET_ERROR,
    payload: error
})

export const sendWebsocketMessage = (data) => ({
    type: SEND_WEBSOCKET_MESSAGE,
    payload: data
})

export const websocketMessage = (messages) => ({
    type: WEBSOCKET_MESSAGE,
    payload: messages
})

export const setDefaultMessage = () => ({
    type: SET_DEFAULT_MESSAGE,
})