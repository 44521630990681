import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_HOST, GET_EMPLOYEE } from "./actionTypes"
import {
    getCompanySuccess,
    getCompanyFail,
    getEmployeeSuccess,
    getEmployeeFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getHost, getEmployee } from "../../api/host"

function* fetchHost({ payload: customer_id }) {
    try {
        const response = yield call(getHost, customer_id)
        yield put(getCompanySuccess(response))
    } catch (error) {
        yield put(getCompanyFail(error))
    }
}

function* fetchEmployee({ payload: data }) {
    try {
        const response = yield call(getEmployee, data)
        yield put(getEmployeeSuccess(response))
    } catch (error) {
        yield put(getEmployeeFail(error))
    }
}

function* hostSaga() {
    yield takeEvery(GET_HOST, fetchHost)
    yield takeEvery(GET_EMPLOYEE, fetchEmployee)
}

export default hostSaga
