import React, { useEffect, useState, useRef } from 'react'
import QrReader from 'react-web-qr-reader'
import { useNavigate, useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import "./index.css";
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import CheckReload from '../../../RouterURL/CheckReload';

const GetInfoFromQR = (props) => {
    const { onSetDataFromQR } = props;
    const qrRef = useRef(null);
    const [facingMode, setFacingMode] = useState("environment");
    const params = useParams();
    const navigate = useNavigate();

    const handleScan = result => {
        if (result) {
            const info = result.data.split("|");
            if (info.length >= 6) {
                let format = {
                    identity_id: info[0],
                    visitor_name: info[2],
                    birth: info[4] === '' ? '' : moment(info[4], 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    address: info[5],
                    issued_on: info[6] === '' ? '' : moment(info[6], 'DD-MM-YYYY').format('YYYY-MM-DD'),
                }
                onSetDataFromQR(format)
                return navigate(`/profile/new/${params.customer_id}`);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "QR không đúng định dạng của CCCD!",
                });
            }

        }
    }
    const handleError = err => {
        console.error(err)
    }

    const handelFacingMode = () => {
        if (facingMode === 'environment') {
            setFacingMode('user');
        } else if (facingMode === 'user') {
            setFacingMode('environment');
        }
    }

    return (
        <div className="card">
            <div className="card-body text-lg-start text-center pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <Link to={'/profile/new/' + params.customer_id}>
                            <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                        </Link>
                    </div>
                </div>
                <div className='text-center'>
                    <h5 className='mt-4'>Quét QR trên CCCD để lấy thông tin</h5>

                    <img width="200px" alt='' src='/images/scan-qr-icon.png' />
                    <QrReader
                        ref={qrRef}
                        className="qr-image-wrapper"
                        facingMode={facingMode}
                        // // delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />



                </div>
                <div className='row'>
                    <div className='col-12'>
                        <a onClick={handelFacingMode} className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0">
                            <i className="fa-solid fa-camera-rotate me-1"></i>
                            <span>Xoay</span>
                        </a>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </div>
    )
}

GetInfoFromQR.propTypes = {
    setting: PropTypes.object,
    onSetDataFromQR: PropTypes.func
}

const mapStateToProps = ({ setting }) => ({
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onSetDataFromQR: (data) => dispatch({ type: "SET_DATA_FROM_QR", payload: data }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GetInfoFromQR)
