import React, { useEffect, useState, useRef } from 'react'
import QrReader from 'react-web-qr-reader'
import { useNavigate, useParams, Link } from 'react-router-dom';
import swal from 'sweetalert';
import "./index.css";

import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { getVisitorByQR } from '../../../../store/actions';
import CheckReload from '../../../RouterURL/CheckReload';

const QRCheckin = (props) => {
    const { onGetVisitor, visitorStatusCode } = props;
    const qrRef = useRef(null);
    const [facingMode, setFacingMode] = useState("environment");
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (visitorStatusCode === 200) {
            let url = "/profile/show/" + params.customer_id;
            return navigate(url)
        } else if (visitorStatusCode === 202) {
            swal({
                icon: "error",
                title: "Lỗi 500!",
                text: 'Không tìm thấy dữ liệu khách. Vui lòng liên hệ với lễ tân hoặc đăng ký mới!',
                type: "error",
                showConfirmButton: false
            }).then(function () {
                return navigate("/qr-face-capture/" + params.customer_id)
            });
        }
    }, [visitorStatusCode])

    const handleScan = result => {

        if (result) {
            let data = {
                customer_id: params.customer_id,
                data: result.data,
                module: 'qr'
            }
            onGetVisitor(data);
        }
    }
    const handleError = err => {
        console.error(err)
    }

    const handelFacingMode = () => {
        if (facingMode === 'environment') {
            setFacingMode('user');
        } else if (facingMode === 'user') {
            setFacingMode('environment');
        }
    }

    return (
        <div className="card">
            <div className="card-body text-lg-start text-center pt-0 p-1 m-2">
                <div className='row'>
                    <div className="col-lg-12 col-12">
                        <Link to={'/checkin-module/' + params.customer_id}>
                            <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                        </Link>
                    </div>
                </div>
                <div className='text-center'>
                    <h5 className='mt-4'>Quét QR để lấy thông tin</h5>

                    <img width="200px" alt='' src='/images/scan-qr-icon.png' />
                    <QrReader
                        ref={qrRef}
                        className="qr-image-wrapper"
                        facingMode={facingMode}
                        // // delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />



                </div>
                <div className='row'>
                    <div className='col-12'>
                        <a onClick={handelFacingMode} className="btn btn-icon form-control bg-gradient-info d-lg-block mt-3 mb-0">
                            <i className="fa-solid fa-camera-rotate me-1"></i>
                            <span>Xoay</span>
                        </a>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </div>
    )
}

QRCheckin.propTypes = {
    visitorStatusCode: PropTypes.number,
    visitor: PropTypes.object,
    setting: PropTypes.object,
    onGetVisitor: PropTypes.func,
}

const mapStateToProps = ({ visitor, setting }) => ({
    visitor: visitor.data,
    visitorStatusCode: visitor.status,
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onGetVisitor: (data) => dispatch(getVisitorByQR(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QRCheckin)
