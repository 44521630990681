/* VISITOR */
export const GET_VISITOR_BY_FACE = "GET_VISITOR_BY_FACE"
export const GET_VISITOR_BY_FACE_SUCCESS = "GET_VISITOR_BY_FACE_SUCCESS"
export const GET_VISITOR_BY_FACE_FAIL = "GET_VISITOR_BY_FACE_FAIL"

export const GET_VISITOR_BY_QR = "GET_VISITOR_BY_QR"
export const GET_VISITOR_BY_QR_SUCCESS = "GET_VISITOR_BY_QR_SUCCESS"
export const GET_VISITOR_BY_QR_FAIL = "GET_VISITOR_BY_QR_FAIL"

export const STORE_VISITOR = "STORE_VISITOR"
export const STORE_VISITOR_SUCCESS = "STORE_VISITOR_SUCCESS"
export const STORE_VISITOR_FAIL = "STORE_VISITOR_FAIL"

export const UPDATE_VISITOR = "UPDATE_VISITOR"
export const UPDATE_VISITOR_SUCCESS = "UPDATE_VISITOR_SUCCESS"
export const UPDATE_VISITOR_FAIL = "UPDATE_VISITOR_FAIL"

export const SET_IMAGE = "SET_IMAGE"
export const SET_IMAGE_SUCCESS = "SET_IMAGE_SUCCESS"

export const SET_MEETINGTYPE = "SET_MEETINGTYPE"
export const SET_MEETINGTYPE_SUCCESS = "SET_MEETINGTYPE_SUCCESS"

export const SET_HOST = "SET_HOST"
export const SET_HOST_SUCCESS = "SET_HOST_SUCCESS"

export const SET_TIME = "SET_TIME"
export const SET_TIME_SUCCESS = "SET_TIME_SUCCESS"

export const SET_THING = "SET_THING"
export const SET_THING_SUCCESS = "SET_THING_SUCCESS"

export const SET_NDA = "SET_NDA"
export const SET_NDA_SUCCESS = "SET_NDA_SUCCESS"

export const SET_MODULE = "SET_MODULE"
export const SET_MODULE_SUCCESS = "SET_MODULE_SUCCESS"

export const SAVE_MEETING = "SAVE_MEETING"
export const SAVE_MEETING_SUCCESS = "SAVE_MEETING_SUCCESS"
export const SAVE_MEETING_FAIL = "SAVE_MEETING_FAIL"

export const CHECKOUT = "CHECKOUT"
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS"
export const CHECKOUT_FAIL = "CHECKOUT_FAIL"

export const RESET_STATE = "RESET_STATE"