//tasks
import setting from "./setting/reducer"
import visitor from "./visitor/reducer"
import country from "./country/reducer"
import meeting_types from "./meeting-types/reducer"
import hosts from "./hosts/reducer"
import wsSocket from "./websocket/reducer"

import { combineReducers } from "redux"

const rootReducer = combineReducers({
    setting,
    visitor,
    country,
    meeting_types,
    hosts,
    wsSocket,
})

export default rootReducer