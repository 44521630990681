import PropTypes from "prop-types";
import React, { Component, useEffect } from 'react';
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { SemipolarLoading } from 'react-loadingg';
import { loadModels, getFullFaceDescription, extractFaceFromBox } from '../../../helpers/face';
import _ from 'lodash';
import withRouter from '../../RouterURL/withRouter';
import swal from 'sweetalert';
import CheckReload from '../../RouterURL/CheckReload';
import { sendWebsocketMessage, getVisitorByFace, setImage, checkout, resetState } from '../../../store/actions'
import Result from "../Result";

const WIDTH = 640;
const HEIGHT = 480;
const inputSize = 224;

class VideoStream extends Component {
    constructor(props) {
        super(props);


        this.state = {
            imageSrc: "",
            isInit: true,

            fullDesc: null,
            detections: null,
            descriptors: null,
            faceMatcher: null,
            match: null,
            face: null,

            messagesRec: this.props.messagesRec,
            lableText: '',
            recColor: 'green',
            countUnknown: 0,
        }
        this.webcam = React.createRef();
    }

    componentDidMount = async () => {
        await loadModels();
        this.setInputDevice();
        this._isMounted = true;
    }

    setInputDevice = () => {
        navigator.mediaDevices.enumerateDevices().then(async devices => {
            let inputDevice = await devices.filter(
                device => device.kind === 'videoinput'
            );
            if (inputDevice.length < 2) {
                await this.setState({
                    facingMode: 'user'
                });
            } else {
                await this.setState({
                    facingMode: { exact: 'environment' }
                });
            }
            this.startCapture();
        });
    };

    startCapture = () => {
        this.interval = setInterval(() => {
            this.capture();
        }, 500);
    };

    capture = async () => {
        const customer_id = this.props.params.customer_id;
        // .canvas !== null
        if (!!this.webcam.current) {
            let imageSrc = this.webcam.current.getScreenshot();
            if (imageSrc !== null) {
                // Get face Detection Box
                await getFullFaceDescription(
                    this.webcam.current.getScreenshot(),
                    inputSize
                ).then(fullDesc => {
                    if (!!fullDesc) {
                        this.setState({
                            isInit: false,
                            detections: fullDesc.map(fd => fd.detection),
                            descriptors: fullDesc.map(fd => fd.descriptor)
                        });
                        let detections = fullDesc.map(fd => fd.detection);
                        if (detections.length > 0) {
                            let data = { "command": "face_recognize", "image": imageSrc };
                            let lableText = "Đang xác minh...";
                            let recColor = 'red';
                            this.props.onSendWebsocket(data);
                            if (this.props.messagesRec?.result === false) {
                                if (this.props.messagesRec?.message !== 'Spoofing face') {
                                    this.setState({ countUnknown: this.state.countUnknown + 1 })
                                }
                                lableText = this.props.messagesRec?.message;
                                recColor = this.props.messagesRec?.message === 'Spoofing face' || this.props.messagesRec?.message === 'unknown' ? 'red' : 'green'
                            } else {
                                this.props.onSetImage({
                                    register: imageSrc,
                                    detail: imageSrc
                                })
                                let visitor_id = this.props.messagesRec?.message?.map((face) => face.visitors);
                                let user_id = this.props.messagesRec?.message?.map((face) => face.userid);
                                if (visitor_id && user_id) {
                                    lableText = "Đã đăng ký";
                                    recColor = 'green'
                                    let params = { customer_id: customer_id, visitor_id: visitor_id[0], code: 'no-id', module: 'fr' }

                                    this.props.onCheckout(params); // Dòng này để data check out
                                    if (this.props.checkoutMessage?.status_code === 200) {
                                        return this.props.navigate('/result/' + customer_id);
                                    } else if (_.isEmpty(this.props.checkoutMessage) || this.props.checkoutMessage === '') {
                                        return this.props.navigate('/result/' + customer_id);
                                    }
                                }
                            }
                            this.setState({
                                lableText: lableText,
                                recColor: recColor
                            });
                        }
                    }
                });


                if (!!this.state.descriptors && !!this.state.faceMatcher) {
                    let match = await this.state.descriptors.map(descriptor =>
                        this.state.faceMatcher.findBestMatch(descriptor)
                    );
                    this.setState({ match: match });
                }
            }

        }
    };


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { detections, match, face } = this.state;
        let videoConstraints = null;
        videoConstraints = {
            width: WIDTH,
            facingMode: 'user'
        };

        let drawBox = null;
        if (!!detections) {

            drawBox = detections.map((detection, i) => {
                let _H = detection.box.height;
                let _W = detection.box.width;
                let _X = detection.box._x;
                let _Y = detection.box._y;

                return (
                    <div key={i}>
                        <div
                            style={{
                                border: 'solid',
                                borderColor: this.state.recColor,
                                height: _H,
                                width: _W,
                                transform: `translate(${_X}px,${_Y}px)`
                            }}
                        >
                            {!!match && !!match[i] ? (
                                <p
                                    style={{
                                        backgroundColor: this.state.recColor,
                                        border: 'solid',
                                        borderColor: this.state.recColor,
                                        marginTop: 0,
                                        color: '#fff',
                                        transform: `translate(-3px,${_H}px)`
                                    }}
                                >
                                    {match[i]._label}
                                </p>
                            ) : null}
                        </div>
                    </div>
                );
            });
        }

        return (
            <div className="card">
                <div className="card-body text-center">
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            {/* <a href={"/checkin-module/" + this.props.params.customer_id} style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</a> */}
                        </div>
                    </div>
                    <h5 className="text-dark mt-3">Nhận diện khuôn mặt checkout</h5>
                    <img className="mt-3" width="30%" src="/images/face-recognition.png" alt='' />

                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div
                                className="Camera"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >


                                <div
                                    style={{
                                        width: "100%",
                                        height: HEIGHT
                                    }}
                                >
                                    <div>
                                        {this.state.isInit && <p>Đang khởi tạo. Vui lòng đợi...</p>}
                                        <p className="text-warning">Vui lòng tháo khẩu trang!</p>
                                        <p id="message" className='text-secondary font-weight-bold'>{this.state.lableText}</p>

                                        {!!videoConstraints ? (
                                            <div className='me-4' style={{ position: 'absolute' }}>
                                                <Webcam
                                                    className="border border-secondary rounded"
                                                    audio={false}
                                                    width="100%"
                                                    ref={this.webcam}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={videoConstraints}
                                                />
                                            </div>
                                        ) : null}
                                        {!!drawBox ? drawBox : null}
                                        {this.state.isInit && <SemipolarLoading color={'#33D7FF'} />}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <CheckReload></CheckReload>
            </div>

        );
    }
}

VideoStream.propTypes = {
    onSendWebsocket: PropTypes.func,
    onGetVisitorFR: PropTypes.func,
    messagesRec: PropTypes.object,
    visitor: PropTypes.any,
    visitorStatusCode: PropTypes.number,
    onSetImage: PropTypes.func,
    onCheckout: PropTypes.func,
    checkoutMessage: PropTypes.any,
    onSetVisitorToDefault: PropTypes.func
}

const mapStateToProps = ({ wsSocket, visitor }) => ({
    messagesRec: wsSocket.messages,
    visitor: visitor.data,
    visitorStatusCode: visitor.status,
    checkoutMessage: visitor.checkout_message
});

const mapDispatchToProps = dispatch => ({
    onSendWebsocket: (data) => (dispatch(sendWebsocketMessage(data))),
    onGetVisitorFR: (params) => dispatch(getVisitorByFace(params)),
    onSetImage: (data) => dispatch(setImage(data)),
    onCheckout: (data) => dispatch(checkout(data)),
    onSetVisitorToDefault: () => dispatch(resetState())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoStream));