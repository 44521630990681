import {
    GET_HOST,
    GET_EMPLOYEE,

    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,

    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAIL,

    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
} from "./actionTypes"

export const getHost = (customer_id) => ({
    type: GET_HOST,
    payload: customer_id
})

export const getEmployee = (data) => ({
    type: GET_EMPLOYEE,
    payload: data
})

export const getCompanySuccess = company => ({
    type: GET_COMPANY_SUCCESS,
    payload: company,
})

export const getCompanyFail = error => ({
    type: GET_COMPANY_FAIL,
    payload: error,
})

export const getDepartmentSucces = department => ({
    type: GET_DEPARTMENT_SUCCESS,
    payload: department,
})


export const getDepartmentFail = error => ({
    type: GET_DEPARTMENT_FAIL,
    payload: error,
})

export const getEmployeeSuccess = employee => ({
    type: GET_EMPLOYEE_SUCCESS,
    payload: employee,
})


export const getEmployeeFail = error => ({
    type: GET_EMPLOYEE_FAIL,
    payload: error,
})