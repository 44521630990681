import {
    GET_VISITOR_BY_FACE,
    GET_VISITOR_BY_FACE_SUCCESS,
    GET_VISITOR_BY_FACE_FAIL,

    GET_VISITOR_BY_QR,
    GET_VISITOR_BY_QR_SUCCESS,
    GET_VISITOR_BY_QR_FAIL,

    STORE_VISITOR,
    STORE_VISITOR_SUCCESS,
    STORE_VISITOR_FAIL,

    UPDATE_VISITOR,
    UPDATE_VISITOR_SUCCESS,
    UPDATE_VISITOR_FAIL,

    SET_IMAGE,
    SET_IMAGE_SUCCESS,

    SET_MEETINGTYPE,
    SET_MEETINGTYPE_SUCCESS,

    SET_HOST,
    SET_HOST_SUCCESS,

    SET_TIME,
    SET_TIME_SUCCESS,

    SET_THING,
    SET_THING_SUCCESS,

    SET_NDA,
    SET_NDA_SUCCESS,

    SET_MODULE,
    SET_MODULE_SUCCESS,

    SAVE_MEETING,
    SAVE_MEETING_SUCCESS,
    SAVE_MEETING_FAIL,

    CHECKOUT,
    CHECKOUT_SUCCESS,
    CHECKOUT_FAIL,

    RESET_STATE
}
    from "./actionTypes"

export const getVisitorByFace = (params) => ({
    type: GET_VISITOR_BY_FACE,
    payload: params
})

export const getVisitorByFaceSuccess = (data) => ({
    type: GET_VISITOR_BY_FACE_SUCCESS,
    payload: data
})

export const getVisitorByFaceFail = (error) => ({
    type: GET_VISITOR_BY_FACE_FAIL,
    payload: error
})

export const getVisitorByQR = (params) => ({
    type: GET_VISITOR_BY_QR,
    payload: params
})

export const getVisitorByQRSuccess = (data) => ({
    type: GET_VISITOR_BY_QR_SUCCESS,
    payload: data
})

export const getVisitorByQRFail = (error) => ({
    type: GET_VISITOR_BY_QR_FAIL,
    payload: error
})

export const storeVisitor = (data) => ({
    type: STORE_VISITOR,
    payload: data
})

export const storeVisitorSuccess = (data) => ({
    type: STORE_VISITOR_SUCCESS,
    payload: data
})

export const storeVisitorFail = (error) => ({
    type: STORE_VISITOR_FAIL,
    payload: error
})

export const updateVisitor = (data) => ({
    type: UPDATE_VISITOR,
    payload: data
})

export const updateVisitorSuccess = (data) => ({
    type: UPDATE_VISITOR_SUCCESS,
    payload: data
})

export const updateVisitorFail = (error) => ({
    type: UPDATE_VISITOR_FAIL,
    payload: error
})
// 

export const setImage = (data) => ({
    type: SET_IMAGE,
    payload: data
})

export const setImageSuccess = (data) => ({
    type: SET_IMAGE_SUCCESS,
    payload: data
})
// 

export const setHost = (data) => ({
    type: SET_HOST,
    payload: data
})

export const setHostSuccess = (data) => ({
    type: SET_HOST_SUCCESS,
    payload: data
})
//

export const setTime = (data) => ({
    type: SET_TIME,
    payload: data
})

export const setTimeSuccess = (data) => ({
    type: SET_TIME_SUCCESS,
    payload: data
})
//

export const setMeeting = (data) => ({
    type: SET_MEETINGTYPE,
    payload: data
})

export const setMeetingSuccess = (data) => ({
    type: SET_MEETINGTYPE_SUCCESS,
    payload: data
})
// 

export const setThing = (data) => ({
    type: SET_THING,
    payload: data
})

export const setThingSuccess = (data) => ({
    type: SET_THING_SUCCESS,
    payload: data
})
// 

export const setNDA = (data) => ({
    type: SET_NDA,
    payload: data
})

export const setNDASuccess = (data) => ({
    type: SET_NDA_SUCCESS,
    payload: data
})

export const setModule = (data) => ({
    type: SET_MODULE,
    payload: data
})

export const setModuleSuccess = (data) => ({
    type: SET_MODULE_SUCCESS,
    payload: data
})


export const saveMeeting = (data) => ({
    type: SAVE_MEETING,
    payload: data
})

export const saveMeetingSuccess = (data) => ({
    type: SAVE_MEETING_SUCCESS,
    payload: data
})

export const saveMeetingFail = (error) => ({
    type: SAVE_MEETING_FAIL,
    payload: error
})

export const checkout = (data) => ({
    type: CHECKOUT,
    payload: data
})

export const checkoutSuccess = (message) => ({
    type: CHECKOUT_SUCCESS,
    payload: message
})

export const checkoutFail = (error) => ({
    type: CHECKOUT_FAIL,
    payload: error
})

export const resetState = () => ({
    type: RESET_STATE,
});