import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { SemipolarLoading } from 'react-loadingg';
import { connect } from "react-redux"
import { websocketOpen, getSetting } from '../../../store/actions';

const Home = (props) => {
    const params = useParams();

    const { loading, setting, onGetSetting, onOpenWebsocket } = props
    useEffect(() => {
        const customer_id = params.customer_id;
        const device_code = params.device_code;
        localStorage.setItem('secret_code', device_code)
        onGetSetting(customer_id, device_code);

    }, [onGetSetting])

    useEffect(() => {
        if ("AI_tech" in setting) {
            onOpenWebsocket(setting.AI_tech)
        }
    }, [setting])

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-header text-center pt-4 pb-3">
                    <img width="200px" src={setting.company.company_logo} alt='' />
                    <h5 className="text-dark">{setting.company.company_name} kính chào quý khách</h5>
                </div>
                <div className="card-body text-lg-start text-center pt-0">



                    {loading && (
                        <SemipolarLoading color={'#33D7FF'} />
                    )}
                    {(setting.length !== 0 && setting.setting.web_check_in === 1) && (

                        <>
                            <Link to={"/checkin-module/" + params.customer_id}>
                                <button className="btn btn-icon form-control bg-gradient-success d-lg-block mt-3 mb-0">
                                    Checkin
                                    <i className="fas fa-arrow-right ms-1"></i>
                                </button>
                            </Link>
                            <Link to={"/checkout-module/" + params.customer_id}>
                                <button className="btn btn-icon form-control bg-gradient-danger d-lg-block mt-3 mb-0">
                                    Checkout
                                    <i className="fas fa-arrow-left ms-1"></i>
                                </button>
                            </Link>
                        </>
                    ) || (setting.length !== 0 && setting.setting.web_check_in === 0) && (
                        <div className="alert alert-warning d-flex align-items-center justify-content-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                            <div>
                                <span className='text-white text-center'>Xin lỗi! Hiện tại người quản trị chưa bật chức năng này</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </React.Fragment>

    );
}

Home.propTypes = {
    setting: PropTypes.object,
    onGetSetting: PropTypes.func,
    onOpenWebsocket: PropTypes.func
}

const mapStateToProps = ({ setting }) => ({
    setting: setting.setting,
    loading: setting.loading
})


const mapDispatchToProps = dispatch => ({
    onGetSetting: (customer_id, device_code) => dispatch(getSetting([customer_id, device_code])),
    onOpenWebsocket: (socket_url) => dispatch(websocketOpen(socket_url))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)
