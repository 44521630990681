import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CheckReload = () => {
    const [reload, setReload] = useState(1);
    const params = useParams();
    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type == 1) {
                window.location.href = '/' + params.customer_id + "/" + localStorage.getItem('secret_code');
            }
        }
    }, [reload])
}

export default CheckReload;