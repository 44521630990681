import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import VideoStream from '../v1/CheckinModule/VideoStream';
import CaptureImage from '../v1/CheckinModule/CaptureImage';
import QRCheckin from '../v1/CheckinModule/QRCheckin/index';
import CaptureFace from '../v1/CheckinModule/QRCheckin/CaptureFace';

import Home from '../v1/Home/index';
import CheckinModule from '../v1/CheckinModule/index';
import ViewProfile from '../v1/Profile/index';
import EditProfile from '../v1/Profile/Edit';
import NewProfile from '../v1/Profile/New';
import GetInfoFromQR from '../v1/Profile/QRInfo/index';

import MeetingType from '../v1/MeetingType/index';
import Host from '../v1/Host/index';
import MeetingTime from '../v1/MeetingTime/index';
import ThingCarrying from '../v1/ThingCarrying/index';
import NDA from '../v1/NDA/index';

// Checkout
import CheckoutModule from '../v1/CheckoutModule/index';
import CheckoutStream from '../v1/CheckoutModule/VideoStream';
import CheckoutCapture from '../v1/CheckoutModule/CaptureImage';
import QRCheckout from '../v1/CheckoutModule/QRCheckout/index';


import FaceDetection from '../v1/FaceDetection';
import Result from "../v1/Result"

class RouterURL extends Component {
    render() {
        return (
            <Routes>
                <Route exact path="/:customer_id/:device_code" element={<Home />} />
                <Route exact path="/checkin-module/:customer_id" element={<CheckinModule />} />
                <Route exact path="/checkout-module/:customer_id" element={<CheckoutModule />} />

                {/* Module-Checkin */}
                <Route exact path="/face-streaming/:customer_id" element={<VideoStream />} />
                <Route exact path="/face-capture/:customer_id" element={<CaptureImage />} />
                <Route exact path="/qr-checkin/:customer_id" element={<QRCheckin />} />
                <Route exact path="/qr-face-capture/:customer_id" element={<CaptureFace />} />
                {/* End of module-checkin */}

                {/* Module-Checkout */}
                <Route exact path="/checkout/face-streaming/:customer_id" element={<CheckoutStream />} />
                <Route exact path="/checkout/face-capture/:customer_id" element={<CheckoutCapture />} />
                <Route exact path="/qr-checkout/:customer_id" element={<QRCheckout />} />
                {/* End of module-Checkout */}

                <Route exact path="/profile/new/:customer_id" element={<NewProfile />} />
                <Route exact path="/profile/new/get-info-from-qr/:customer_id" element={<GetInfoFromQR />} />

                <Route exact path="/profile/show/:customer_id" element={<ViewProfile />} />
                <Route exact path="/profile/edit/:customer_id" element={<EditProfile />} />
                <Route exact path="/meeting-types/:customer_id" element={<MeetingType />} />
                <Route exact path="/hosts/:customer_id" element={<Host />} />
                <Route exact path="/meeting-time/:customer_id" element={<MeetingTime />} />
                <Route exact path="/thing-carrying/:customer_id" element={<ThingCarrying />} />
                <Route exact path="/nda/:customer_id" element={<NDA />} />
                <Route exact path="/result/:customer_id" element={<Result />} />
                {/* <Route exact path="/face-detection" element={<FaceDetection />} /> */}

            </Routes>
        );
    }
}

export default RouterURL;