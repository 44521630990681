import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { setNDA } from '../../../store/actions';
import SubmitData from '../SubmitData';
import CheckReload from '../../RouterURL/CheckReload';

const NDA = (props) => {
    const params = useParams();
    const { onSetNDA, meetingType, setting } = props;
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [sigPad, setSigPad] = useState({});
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [isSign, setIsSign] = useState(false);
    const refCanvas = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        setCanvasWidth(refCanvas.current ? refCanvas.current.offsetWidth : 0);
    }, [refCanvas.current]);

    useEffect(() => {
        if (meetingType.isActive_nda === 0) {
            setIsSubmit(true);
        }

        if (setting.setting.checkin_one_step === 1) {
            setIsSubmit(true);
        }
    }, [meetingType, setting])

    const clear = () => {
        sigPad.clear();
        setTrimmedDataURL(null);
        onSetNDA(null);
        setIsSign(false);
    }
    const trim = () => {

        if (isCanvasEmpty(sigPad.getTrimmedCanvas())) {
            onSetNDA(null);
            setIsSign(false);
        } else {
            setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
            onSetNDA(sigPad.getTrimmedCanvas().toDataURL('image/png'));
            setIsSign(true);
        }

    }

    function isCanvasEmpty(cnv) {
        const blank = document.createElement('canvas');

        blank.width = cnv.width;
        blank.height = cnv.height;

        return cnv.toDataURL() === blank.toDataURL();
    }

    const handelValidate = () => {
        let errors = {};
        let isNext = true;

        if (!trimmedDataURL) {
            errors.date = {
                type: "required",
                message: "Vui lòng ký tên NDA"
            };
            isNext = false;
        }

        setErrors(errors);
        return isNext;
    }

    const handleNext = () => {
        if (handelValidate()) {
            setIsSubmit(true);
        }
    }

    if (isSubmit) {
        return <SubmitData />
    }

    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/meeting-types/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>

                        </div>
                    </div>
                    <div className='text-center'>
                        <h5 className='mt-4'>Chữ ký NDA</h5>

                        <img width="200px" className='mb-3' alt='' src='/images/nda.jpg' />
                    </div>

                    <div className="row align-items-center">
                        <div className="col-12">

                            <form>
                                <div className='form-group' ref={refCanvas}>
                                    {
                                        canvasWidth > 0 && (
                                            <SignatureCanvas
                                                penColor='black'
                                                canvasProps={{ width: canvasWidth, height: 300, className: 'sigCanvas border border-secondary rounded' }}
                                                ref={(ref) => { setSigPad(ref) }}
                                                id="canvasNDA"
                                            />
                                        )
                                    }

                                </div>

                                <div className="row">
                                    {!isSign &&
                                        <div className='form-group'>
                                            <button type="button" onClick={trim} className="btn btn-danger form-control">Giữ</button>
                                        </div>
                                    }

                                    {isSign && (
                                        <div className='form-group'>
                                            <button type="button" onClick={clear} className="btn btn-light form-control">Xoá</button>
                                        </div>
                                    )}

                                </div>
                                {isSign && (
                                    <div className='row mt-3'>
                                        <div className='col-12 text-center'>
                                            <a onClick={handleNext} style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>Hoàn tất <i className="fa-solid fa-check"></i></a>
                                        </div>
                                    </div>
                                )}

                            </form>
                        </div>
                    </div>

                </div>
                <CheckReload></CheckReload>
            </div>
        </>
    );
}

NDA.propTypes = {
    onSetNDA: PropTypes.func,
    meetingType: PropTypes.object,
    setting: PropTypes.object
}

const mapStateToProps = ({ visitor, setting }) => ({
    meetingType: visitor.meetingType,
    setting: setting.setting
})


const mapDispatchToProps = dispatch => ({
    onSetNDA: (nda) => dispatch(setNDA(nda)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NDA);

