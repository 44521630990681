import axios from "axios"

//pass new generated access token here
const token = process.env.REACT_APP_QIOR_ACCESS_KEY

//apply base url for axios
const API_URL = process.env.REACT_APP_API_DOMAIN

const axiosApi = axios.create({
    baseURL: API_URL,
})

axiosApi.defaults.headers.common["X-Authorization"] = token
axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
)

// with json
export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => {
        return response.data
    })
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}

// with form data
export async function postFormData(url, data, config = {}) {
    return axiosApi
        .post(url, data, { ...config })
        .then(response => response.data)
}
