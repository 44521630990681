import React from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';

import PropTypes from "prop-types";
import { connect } from 'react-redux';
import CheckReload from '../../RouterURL/CheckReload';

const ViewProfile = (props) => {
    const { visitor, setting, module } = props;
    const params = useParams();
    const navigate = useNavigate();

    const handleUrl = () => {
        let urlNext = "";
        if (module === 'qr') {
            urlNext = "/qr-face-capture/" + params.customer_id;
        } else {
            urlNext = "/meeting-types/" + params.customer_id;
        }
        return navigate(urlNext);
    }

    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/checkin-module/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>
                            <Link to={'/profile/edit/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-end m-2'> Chỉnh sửa</span>
                            </Link>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-lg-12 col-12">
                            <div className='text-center'>
                                <img className="border-radius-lg shadow-sm" style={{ width: "100px", objectFit: "fill", borderRadius: '50%' }} id="wizardPicturePreview"
                                    src={visitor?.images === null ? "/images/gambar-user-png-33.png" : "https://portal.qior.com.vn/" + visitor?.images} alt='' />
                                <p className='text-dark m-0 mt-2 font-weight-bold' style={{ fontSize: "14px" }}> {visitor?.visitor_name}</p>
                                <p className='text-dark' style={{ fontSize: "12px" }}>{visitor?.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="nav-wrapper position-relative end-0">
                                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#profile" role="tab" aria-selected="true">
                                            Cá nhân
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#contact" role="tab" aria-selected="false">
                                            Liên lạc
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#job" role="tab" aria-selected="false">
                                            Công việc
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane container-fuild mt-3 active" id="profile" role="tabpanel">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#E3F2FF", color: "#0085FF" }} className="circle-icon fa-solid fa-user me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Họ và tên</span>
                                                    <br />
                                                    {visitor?.visitor_name}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#FFE3FB", color: "#FF58BC" }} className="circle-icon fa-solid fa-cake-candles me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Ngày sinh</span>
                                                    <br />
                                                    {visitor?.birth === null ? '-' : visitor?.birth}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#FDE9DE", color: "#E9900B" }} className="circle-icon fa-solid fa-earth-asia me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Quốc tịch</span>
                                                    <br />
                                                    {visitor?.nationality === null ? '-' : visitor?.nationality}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#F7EFFF", color: "#6165DB" }} className="circle-icon fa-solid fa-id-card me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Chứng minh nhân dân</span>
                                                    <br />
                                                    {visitor?.identity_id === null ? '-' : visitor?.identity_id}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#FFECE8", color: "#F49587" }} className="circle-icon fa-solid fa-calendar-days me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Ngày cấp</span>
                                                    <br />
                                                    {visitor?.issued_on === null ? '-' : visitor?.issued_on}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#EFF7FF", color: "#7B9FE1" }} className="circle-icon fa-solid fa-house-user me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Địa chỉ</span>
                                                    <br />
                                                    {visitor?.address === null ? '-' : visitor?.address}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane container-fuild mt-3 fade" id="contact" role="tabpanel">

                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#F7EFFF", color: "#9999E8" }} className="circle-icon fa-solid fa-address-book me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Số điện thoại</span>
                                                    <br />
                                                    {visitor?.phone_number === null ? '-' : visitor?.phone_number}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#FFECE8", color: "#F7AB9F" }} className="circle-icon fa-solid fa-envelope me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Email</span>
                                                    <br />
                                                    {visitor?.email === null ? '-' : visitor?.email}
                                                </p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="tab-pane container-fuild mt-3 fade" id="job" role="tabpanel">

                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#EFF7FF", color: "#7B9FE1" }} className="circle-icon fa-solid fa-building me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Công ty</span>
                                                    <br />
                                                    {visitor?.company === null ? '-' : visitor?.company}
                                                </p>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-left align-items-center">
                                                <i style={{ backgroundColor: "#FFE3FB", color: "#FF58BC" }} className="circle-icon fa-solid fa-briefcase me-2"></i>
                                                <p className='text-dark m-0 ms-2 font-weight-bold'>
                                                    <span className='text-dark fw-light text-start m-0'>Chức vụ</span>
                                                    <br />
                                                    {visitor?.position === null ? '-' : visitor?.position}
                                                </p>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12 text-center'>
                            <button onClick={handleUrl} style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>Tiếp tục <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <CheckReload></CheckReload>
        </>
    );
}

ViewProfile.propTypes = {
    visitor: PropTypes.any,
    setting: PropTypes.object,
    module: PropTypes.string,
}

const mapStateToProps = ({ visitor, setting }) => ({
    visitor: visitor.data,
    module: visitor.module,
    setting: setting.setting.setting
})

export default connect(mapStateToProps, null)(ViewProfile);