import { GET_SETTING_SUCCESS, GET_SETTING_FAIL, SET_TYPE_REG_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    setting: {
        company: {
            company_logo: '',
            company_name: '',
        },
        setting: {
            web_check_in: -1
        },
    },
    error: {},
    loading: true,
    type: null
}

const setting = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SETTING_SUCCESS:
            return {
                ...state,
                setting: action.payload,
                loading: false,
            }

        case GET_SETTING_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_REG_SUCCESS:
            return {
                ...state,
                type: action.payload,
            }

        default:
            return state
    }
}

export default setting
