/* SETTING */
export const GET_HOST = "GET_HOST"
export const GET_EMPLOYEE = "GET_EMPLOYEE"

export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS"
export const GET_DEPARTMENT_FAIL = "GET_DEPARTMENT_FAIL"

export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS"
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL"
