import {
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAIL,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    companies: [],
    departments: [],
    employees: [],
    error: {},
    loading: true,
}

const hosts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false,
            }

        case GET_COMPANY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                loading: false,
            }

        case GET_DEPARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                loading: false,
            }

        case GET_EMPLOYEE_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default hosts
