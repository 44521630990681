import React, { Component, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";

import "flatpickr/dist/flatpickr.css";
import Flatpickr from "react-flatpickr";

import Select from 'react-select';
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { setTime } from "../../../store/actions"
import CheckReload from '../../RouterURL/CheckReload';
import "./index.css";

const MeetingTime = (props) => {
    registerLocale("vi", vi);
    const params = useParams();
    const navigate = useNavigate();
    const [dateMeeting, setDateMeeting] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [timeIn, setTimeIn] = useState('');
    const [timeOut, setTimeOut] = useState('');
    const { onSetTime, setting } = props;

    const handelValidate = () => {
        let errors = {};
        let isNext = true;

        if (!dateMeeting) {
            errors.date = {
                type: "required",
                message: "Ngày đến gặp không được phép để trống"
            };
            isNext = false;
        }
        else {
            let now = moment(new Date());
            let selectedDate = moment(new Date(dateMeeting));
            let dateDiff = selectedDate.diff(now, 'hours') // 1
            if (dateDiff < 0) {
                errors.date = {
                    type: "day of future",
                    message: "Chỉ có thể chọn ngày hiện tại hoặc ngày trong tương tai"
                };
                isNext = false;
            }
        }

        if (!timeIn) {
            errors.time_in = {
                type: "required",
                message: "Thời gian vào không được phép để trống"
            };
            isNext = false;
        }

        if (!timeOut) {
            errors.time_out = {
                type: "required",
                message: "Thời gian ra không được phép để trống"
            };
            isNext = false;
        }

        if (timeIn && timeOut) {
            let startTime = Date.parse(timeIn);
            let endTime = Date.parse(timeOut);
            if (endTime < startTime) {
                errors.time_out = {
                    type: "time range",
                    message: "Thời gian ra không được nhỏ hơn thời gian vào"
                };
                isNext = false;
            }
        }

        setErrors(errors);
        return isNext;
    }

    useEffect(() => {
        if (setting.setting.pass_through_time_checkin === 0) {
            return navigate("/thing-carrying/" + params.customer_id)
        }
    }, [setting])

    const handleNext = () => {

        if (handelValidate()) {
            onSetTime({
                date: moment(dateMeeting).format('YYYY-MM-DD'),
                timeIn: moment(Date.parse(timeIn)).format("HH:mm"),
                timeOut: moment(Date.parse(timeOut)).format("HH:mm"),
            })
            return navigate("/thing-carrying/" + params.customer_id)
        }
    }

    return (
        <>
            <div className='card'>
                <div className='card-body text-lg-start p-1 m-2'>
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Link to={'/checkin-module/' + params.customer_id}>
                                <span style={{ fontSize: "15px" }} className='text-dark float-start m-2'><i className="fa-solid fa-chevron-left mt-1"></i> Quay lại</span>
                            </Link>

                        </div>
                    </div>
                    <div className='text-center'>
                        <h5 className='mt-4'>Chọn thời gian gặp</h5>

                        <img width="200px" className='mb-3' alt='' src='/images/bussiness_time.jpg' />
                    </div>

                    <div className="row align-items-center">
                        <div className="col-12">
                            <form>


                                <div className="form-group">
                                    <label className='text-dark'>Ngày đến gặp</label>
                                    <br />
                                    <div className="customDatePickerWidth">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            locale="vi"
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={"Ngày/tháng/năm"}
                                            selected={dateMeeting ? moment(dateMeeting, 'DD-MM-YYYY')._d : ''}
                                            onChange={(date) => setDateMeeting(date)}
                                        ></DatePicker>
                                    </div>

                                    {errors.date && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.date.message}</span>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className="form-group col-6">
                                        <label className='text-dark'>Thời gian vào</label>

                                        <Flatpickr
                                            className='form-control'
                                            placeholder={"H:M"}
                                            value={timeIn}
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                disableMobile: true
                                            }}
                                            onChange={(timeIn) => setTimeIn(timeIn)}
                                        />
                                        {errors.time_in && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.time_in.message}</span>
                                        )}
                                    </div>
                                    <div className="form-group col-6">
                                        <label className='text-dark'>Thời gian ra</label>
                                        <Flatpickr
                                            className='form-control'
                                            placeholder={"H:M"}
                                            value={timeOut}
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                disableMobile: true
                                            }}
                                            onChange={(timeOut) => setTimeOut(timeOut)}
                                        />
                                        {errors.time_out && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.time_out.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12 text-center'>
                                        <a onClick={handleNext} style={{ fontSize: "15px" }} className='btn bg-gradient-info form-control'>Tiếp tục <i className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <CheckReload></CheckReload>
        </>
    );
}

MeetingTime.propTypes = {
    onSetTime: PropTypes.func,
    setting: PropTypes.object
}

const mapStateToProps = ({ setting }) => ({
    setting: setting.setting
})

const mapDispatchToProps = dispatch => ({
    onSetTime: (time) => dispatch(setTime(time))
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingTime);