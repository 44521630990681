import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import moment from "moment";
import { sendWebsocketMessage, saveMeeting } from "../../store/actions";
import swal from 'sweetalert';
import _ from "lodash"
import { useParams } from "react-router-dom";

const SubmitData = (props) => {
    const {
        visitor,
        imageMeeting,
        meetingType,
        host,
        meetingTime,
        thingCarrying,
        nda,
        onStoreVisitor,
        setting,
        onSendWebsocket,
        visitorMessage,
        socketMessage
    } = props;
    const params = useParams();

    const registerQuickVistor = {
        id: '',
        visitor_name: `Khách #${Math.floor(Math.random() * 900000) + 100000}`,
        birth: '',
        nationality: '',
        identity_id: '',
        issued_on: '',
        address: '',
        phone_number: '0000000000',
        email: '',
        company: '-',
        position: '-',
        images: null,
    }

    const createQuickTime = {
        date: moment(new Date()).format('YYYY-MM-DD'),
        timeIn: moment(new Date()).format('HH:mm'),
        timeOut: moment(new Date()).add(1, 'hours').format('HH:mm'),
    }

    useEffect(() => {
        if (setting.checkin_one_step === 1) {
            const dataOneStep = {
                device_id: localStorage.getItem('secret_code'),
                visitor: registerQuickVistor,
                image: imageMeeting?.detail,
                meeting_time: createQuickTime,
            };
            if (!_.isEmpty(visitor)) {
                dataOneStep.visitor = visitor
            }
            onStoreVisitor({
                customer_id: params.customer_id,
                data: dataOneStep
            });
        }
        else {
            const data = {
                device_id: localStorage.getItem('secret_code'),
                visitor: visitor,
                image: imageMeeting?.detail,
                visitor_type: meetingType?.id,
                hosts: host
            }
            if (meetingType?.isActive_photo_carry === 1 || meetingType?.isActive_things_carry === 1) {
                data.thing_carrying = thingCarrying;
            }
            if (meetingType?.isActive_nda === 1) {
                data.nda = nda;
            }
            if (setting.pass_through_time_checkin === 0) {
                data.meeting_time = createQuickTime;
            } else {
                data.meeting_time = meetingTime;
            }
            onStoreVisitor({
                customer_id: params.customer_id,
                data: data
            });
        }
    }, [onStoreVisitor])

    useEffect(() => {
        const result = visitorMessage;
        if (!_.isEmpty(result)) {
            let data = {
                "command": "visitor_mapping",
                "userid": localStorage.getItem('image_id'),
                "visitorid": result.visitor_id
            }
            onSendWebsocket(data);
        }
    }, [visitorMessage, onSendWebsocket])

    useEffect(() => {
        if (socketMessage?.result === true && socketMessage?.status === '') {
            swal({
                icon: "success",
                title: "Success!",
                text: "Bạn đã hoàn tất thủ tục đăng ký cuộc hẹn!",
                type: "success",
                timer: 2000,
                showConfirmButton: false
            }).then(function () {
                let url_home = "/" + params.customer_id + "/" + localStorage.getItem('secret_code')
                localStorage.clear();
                window.location.href = url_home;
            });
        }
    }, [socketMessage])

    return (
        <>
        </>
    );
}

SubmitData.propTypes = {
    onStoreVisitor: PropTypes.func,
    visitor: PropTypes.object,
    imageMeeting: PropTypes.object,
    meetingType: PropTypes.object,
    host: PropTypes.object,
    meetingTime: PropTypes.object,
    thingCarrying: PropTypes.object,
    nda: PropTypes.string,
    onSendWebsocket: PropTypes.func,
    setting: PropTypes.object,
    visitorMessage: PropTypes.object,
    socketMessage: PropTypes.object
}

const mapStateToProps = ({ visitor, setting, wsSocket }) => ({
    visitor: visitor.data,
    imageMeeting: visitor.imageMeeting,
    meetingType: visitor.meetingType,
    host: visitor.host,
    meetingTime: visitor.meetingTime,
    thingCarrying: visitor.thingCarrying,
    nda: visitor.nda,
    setting: setting.setting.setting,
    visitorMessage: visitor.message,
    socketMessage: wsSocket.messages
})

const mapDispatchToProps = dispatch => ({
    onStoreVisitor: (data) => dispatch(saveMeeting(data)),
    onSendWebsocket: (message) => dispatch(sendWebsocketMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitData);